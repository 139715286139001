import React, { useState } from "react";
import { Container, Row, Col, Nav, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";

import "./News.css";

const News = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [visibleItems, setVisibleItems] = useState({
    2024: 3,
    2023: 3,
    2022: 3,
    2021: 3,
    2020: 3,
    all: 3,
  });

  const news2024 = [
    {
      title: "Korea Biomedical Review",
      subtitle:
        "MINDSAI aims to tackle depression with innovative saliva-based diagnosis",
      body: "2024-08-14",
      url: "https://www.koreabiomed.com/news/articleView.html?idxno=24853",
    },

    {
      title: "의학신문",
      subtitle: "건협, 근로자 대상 ‘MEDI-CHECK 마음건강검진’ 서비스 큰 호응",
      body: "2024-07-11",
      url: "http://www.bosa.co.kr/news/articleView.html?idxno=2226860",
    },

    {
      title: "사이언스조선",
      subtitle: "우울증, 침 속 '스트레스 호르몬'으로 진단한다",
      body: "2024-05-30",
      url: "https://biz.chosun.com/science-chosun/medicine-health/2024/05/30/ZTV23FENEFAV5M4PJADSHKOTOI/",
    },

    {
      title: "이데일리",
      subtitle: "마인즈에이아이, 10억원 규모 시리즈A 후속 투자 유치",
      body: "2024-05-07",
      url: "https://www.edaily.co.kr/News/Read?newsId=02217286638886624&mediaCodeNo=257&OutLnkChk=Y",
    },

    {
      title: "중부일보",
      subtitle: "한국건강관리협회, 'MEDI-CHECK 마음건강검진' 서비스 실시",
      body: "2024-03-26",
      url: "https://www.joongboo.com/news/articleView.html?idxno=363643564",
    },

    {
      title: "비지니스리포트",
      subtitle: "[마인즈에이아이] 침으로 우울증 분류하는 ‘마인즈 내비’ 개발",
      body: "2024-02-26",
      url: "http://www.businessreport.kr/news/articleView.html?idxno=42100",
    },
  ];

  const news2023 = [
    {
      title: "한국경제",
      subtitle: "평균 60억 투자 유치...주목할 상반기 아기유니콘 4곳은?",
      body: "2023-07-26",
      url: "https://www.hankyung.com/economy/article/202307170072i",
    },

    {
      title: "서울특별시",
      subtitle: "'23년 서울 청년 마음건강 지원, 2,500명 규모로 하반기 첫 모집",
      body: "2023-07-24",
      url: "https://www.seoul.go.kr/news/news_report.do#view/393233",
    },

    {
      title: "매일경제",
      subtitle: "51개사 아기유니콘 선정",
      body: "2023-05-30",
      url: "https://www.mk.co.kr/news/it/10748617",
    },

    {
      title: "더스탁",
      subtitle:
        "연평균 20% 이상 성장하는 디지털치료제 시장... 정조준한 K-DTx 스타트업들",
      body: "2023-03-16",
      url: "https://www.the-stock.kr/news/articleView.html?idxno=18016",
    },

    {
      title: "아주경제",
      subtitle: '"국내 연평균 27% 성장 예고"... 디지털 치료제 주목도 ↑',
      body: "2023-01-18",
      url: "https://www.ajunews.com/view/20230117150028570",
    },

    {
      title: "조선일보",
      subtitle:
        "올해 디지털치료제 원년으로 기록되나?...최소 2개 제품 상용화 임박",
      body: "2023-01-02",
      url: "https://it.chosun.com/news/articleView.html?idxno=2022123001042",
    },
  ];

  const news2022 = [
    {
      title: "메디게이트뉴스",
      subtitle: "디지털치료제 허가 위한 확증임상시험 7곳 진행 중",
      body: "2022-12-25",
      url: "https://www.medigatenews.com/news/1980836482",
    },

    {
      title: "의협신문",
      subtitle: "고위험 우울증, 타액으로 예측한다",
      body: "2022-07-07",
      url: "https://www.doctorsnews.co.kr/news/articleView.html?idxno=145283",
    },

    {
      title: "이뉴스투데이",
      subtitle: "주목도 커지는 '디지털치료제' 상용화 탄력 받나",
      body: "2022-05-21",
      url: "https://www.enewstoday.co.kr/news/articleView.html?idxno=1571324",
    },

    {
      title: "한국경제",
      subtitle: '목소리만 듣고 "우울하군요"…AI가 마음의 병 치료 돕는다 [긱스]',
      body: "2022-05-17",
      url: "https://www.hankyung.com/it/article/2023051720391",
    },

    {
      title: "아시아경제",
      subtitle: '"타액으로 우울증 진단 정확도 높였죠"',
      body: "2022-02-03",
      url: "https://www.asiae.co.kr/article/2022020220151642889",
    },

    {
      title: "아시아경제",
      subtitle: "마인즈에이아이, 정신건강 SW  2종 식약처 임상실시 승인",
      body: "2022-01-06",
      url: "https://view.asiae.co.kr/article/2022010614304799807",
    },
  ];

  const news2021 = [
    {
      title: "의학신문",
      subtitle: '마인즈에이아이 "혁신 국가대표 기업" 선정',
      body: "2021-12-16",
      url: "http://www.bosa.co.kr/news/articleView.html?idxno=2164560",
    },

    {
      title: "메디게이트청년의사",
      subtitle: '"디지털 기술 통해 정신건강의학과 문턱 낮춰야"',
      body: "2021-12-06",
      url: "https://www.docdocdoc.co.kr/news/articleView.html?idxno=2017229",
    },

    {
      title: "메디게이트뉴스",
      subtitle:
        "정신과질환 치료장벽 낮추고 치매 예방까지... 국내 디지털치료제 연구개발 한창",
      body: "2021-08-21",
      url: "https://www.medigatenews.com/news/2257353082",
    },
  ];

  const news2020 = [
    {
      title: "쿠키뉴스",
      subtitle: "코로나19 현장 탈진(번아웃)증후군 관리 위해 협력",
      body: "2020-11-10",
      url: "https://www.kukinews.com/newsView/kuk202011100388",
    },

    {
      title: "신아일보",
      subtitle: "복지부, 코로나19 번아웃증후군 관리 나선다",
      body: "2020-11-10",
      url: "https://www.shinailbo.co.kr/news/articleView.html?idxno=1341240",
    },

    {
      title: "마인드포스트",
      subtitle: "국립정신건강센터, 마인즈에이아이와 업무협약 체결",
      body: "2020-11-10",
      url: "http://www.mindpost.or.kr/bbs/board.php?bo_table=news&wr_id=3711&sfl=wr_subject%7C%7Cwr_content&stx=%EB%A7%88%EC%9D%B8%EC%A6%88%EC%97%90%EC%9D%B4%EC%95%84%EC%9D%B4&sop=and&page=1",
    },

    ///////////
    {
      title: "닥터W",
      subtitle: "국립정신건강센터 - (주)마인즈에이아이, 업무협약 체결",
      body: "2020-11-09",
      url: "https://www.doctorw.co.kr/news/articleView.html?idxno=88493",
    },

    {
      title: "연합뉴스",
      subtitle: "코로나 우울·'번아웃'등 의료진 정신건강 관리한다",
      body: "2020-11-09",
      url: "https://www.yna.co.kr/view/AKR20201109102600530",
    },

    {
      title: "메디파나뉴스",
      subtitle: "국립정신건강센터, 마인즈에이아이와 업무협약 체결",
      body: "2020-11-09",
      url: "https://www.medipana.com/article/view.php?news_idx=265605",
    },

    {
      title: "동아일보",
      subtitle:
        "한국필립모리스, 소방관 심리치유 지원... 가상현실 정신건강 프로그램 공급",
      body: "2020-10-27",
      url: "https://www.donga.com/news/article/all/20201027/103661428/1",
    },

    {
      title: "매일경제",
      subtitle: "한국필립모리스, 소방관 심리 치유 지원...치유 포레스트 제공",
      body: "2020-10-26",
      url: "https://www.mk.co.kr/news/business/9578316",
    },

    {
      title: "뉴시스",
      subtitle: "한국필립모리스, 대전소방에 소방관 심리치유 장비 기부",
      body: "2020-10-26",
      url: "https://newsis.com/view/?id=NISX20201026_0001211153&cID=10807&pID=10800",
    },

    {
      title: "파이낸셜뉴스",
      subtitle: "한국필립모리스, 대전소방본부에 심리치유 지원",
      body: "2020-10-26",
      url: "https://www.fnnews.com/news/202010261607536404",
    },

    {
      title: "메디컬투데이",
      subtitle: "대전소방, 한국필립모리스와 소방관 심리치유 지원 약정 체결",
      body: "2020-10-26",
      url: "https://www.mdtoday.co.kr/mdtoday/index.html?no=403798",
    },

    {
      title: "더퍼블릭",
      subtitle: "심리상담도 VR시대, 치유심리상담센터에서 정신건강 케어",
      body: "2020-10-21",
      url: "https://www.thepublic.kr/news/articleView.html?idxno=33817",
    },

    {
      title: "테크월드 뉴스",
      subtitle: "코로나블루, 비대면 언택트 심리상담으로 정신건강도 챙기세요",
      body: "2020-10-21",
      url: "https://www.epnc.co.kr/news/articleView.html?idxno=106631",
    },
  ];

  const allNews = [
    ...news2024,
    ...news2023,
    ...news2022,
    ...news2021,
    ...news2020,
  ];

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => ({
      ...prevVisibleItems,
      [activeTab]: prevVisibleItems[activeTab] + 3,
    }));
  };

  const renderCarousel = (newsArray) => {
    return (
      <Carousel
        key={activeTab} // 탭 변경 시 Carousel이 리셋되도록 key를 activeTab으로 설정
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
          },
          desktop: {
            breakpoint: { max: 3000, min: 1480 },
            items: 3,
          },
          tablet: {
            breakpoint: { max: 1480, min: 992 },
            items: 2,
          },
          mobile: {
            breakpoint: { max: 992, min: 0 },
            items: 1,
            slidesToSlide: 1,
          },
        }}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {newsArray.map((news, index) => (
          <div
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(news.url)}
          >
            <h2 className="news-title">{news.title}</h2>
            <h1 className="news-subtitle">{news.subtitle}</h1>
            <p className="news-body">{news.body}</p>
          </div>
        ))}
      </Carousel>
    );
  };

  const renderList = (newsArray) => {
    return (
      <>
        <Row>
          {newsArray.slice(0, visibleItems[activeTab]).map((news, index) => (
            <Col key={index} xs={12} className="mb-3">
              <div
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(news.url)}
              >
                <h2 className="news-title">{news.title}</h2>
                <h1 className="news-subtitle">{news.subtitle}</h1>
                <p className="news-body">{news.body}</p>
              </div>
            </Col>
          ))}
        </Row>
        {visibleItems[activeTab] < newsArray.length && (
          <Button onClick={showMoreItems} className="mb-3">
            더보기
          </Button>
        )}
      </>
    );
  };

  const getNewsArray = () => {
    switch (activeTab) {
      case "2024":
        return news2024;
      case "2023":
        return news2023;
      case "2022":
        return news2022;
      case "2021":
        return news2021;
      case "2020":
        return news2020;
      case "all":
        return allNews;
      default:
        return [];
    }
  };

  const newsArray = getNewsArray();

  return (
    <Container className="news_container">
      <Nav variant="tabs" activeKey={activeTab} onSelect={setActiveTab}>
        <Nav.Item>
          <Nav.Link eventKey="all">All</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2024">2024</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2023">2023</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2022">2022</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2021">2021</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2020">2020</Nav.Link>
        </Nav.Item>
      </Nav>

      <Row>
        <Col>
          <div className="carousel-view">{renderCarousel(newsArray)}</div>
          <div className="list-view">{renderList(newsArray)}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default News;
