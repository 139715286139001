import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";

import "./MyValidation.css";

const schema = yup.object().shape({
  category: yup.string().required("필수 입력 항목입니다."),
  name: yup.string().required("필수 입력 항목입니다."),
  phone: yup
    .string()
    .matches(/^\d{3}-\d{3,4}-\d{4}$/, "올바른 전화번호 형식이 아닙니다.")
    .required("필수 입력 항목입니다."),
  email: yup
    .string()
    .email("정확한 이메일을 입력해주세요.")
    .required("필수 입력 항목입니다."),
  message: yup.string().required("문의 내용을 입력해주세요."),
  preference: yup
    .array()
    .of(yup.string())
    .min(1, "최소 하나 이상의 사업을 선택해주세요.")
    .required("필수 항목입니다."),
  terms: yup
    .bool()
    .oneOf([true], "이용 약관에 동의해야 합니다.")
    .required("이용 약관에 동의해야 합니다."),
});

function MyValidation() {
  const handleSubmit = async (values, { resetForm }) => {
    const emailData = {
      to: "mindsai@mindsai.co.kr",
      subject: `[제휴·제안문의]\n ${values.preference.join(", ")}\n | ${values.Name}\n(${values.category})`,
      text: `구분: ${values.category}\n이름: ${values.Name}\n연락처: ${values.call}\n이메일: ${values.email}\n문의사업: ${values.preference.join(", ")}\n문의내용: ${values.state}`,
      html: `
            <h1 style="font-size: 24px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:20px">제휴제안 문의</h1>
            <p style="font-size: 16px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:10px">구분 | ${values.category}</p>
            <p style="font-size: 16px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:10px">이름 | ${values.Name}</p>
            <p style="font-size: 16px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:10px">연락처 | ${values.call}</p>
            <p style="font-size: 16px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:10px">이메일 | ${values.email}</p>
            <p style="font-size: 16px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:10px">문의사업 | ${values.preference.join(", ")}</p>
            <p style="font-size: 16px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:10px">문의내용 | <br/>${values.state}</p>
          `,
    };

    try {
      const response = await fetch(process.env.REACT_APP_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(emailData),
      });

      if (!response.ok) {
        throw new Error("이메일 전송에 실패했습니다.");
      }

      alert("이메일이 성공적으로 전송되었습니다.");
      resetForm();
    } catch (error) {
      console.error("Email sending failed:", error);
      alert("이메일 전송 중 오류가 발생했습니다. 다시 시도해주세요.");
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        category: "",
        name: "",
        phone: "",
        email: "",
        message: "",
        preference: [],
        terms: false,
      }}
      validateOnChange={false} // 입력 중에는 검증하지 않음 //
      validateOnBlur={false} // 블러 시에도 검증하지 않음 //
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="formCategory">
              <Form.Label>구분</Form.Label>
              <div class="custom-select-wrapper">
                <Form.Control
                  classname="selectbox custom-select"
                  as="select"
                  name="category"
                  value={values.category}
                  onChange={handleChange}
                  isInvalid={!!errors.category} //오류 발생 시에만 에러 표시//
                >
                  <option value="" disabled hidden>
                    -- 구분 선택 --
                  </option>
                  <option value="public" label="공공기관" />
                  <option value="company" label="기업" />
                  <option value="individual" label="개인" />
                  <option value="etc" label="기타" />
                </Form.Control>
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.category}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formName">
              <Form.Label>이름/(회사명 또는 기관명)</Form.Label>
              <Form.Control
                placeholder="이름/(회사명 또는 기관명)"
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="formPhone">
              <Form.Label>연락처</Form.Label>
              <Form.Control
                placeholder="ex) 010-1234-5678"
                type="text"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formEmail">
              <Form.Label>이메일</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  placeholder="ex) mindsai@mindsai.com"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="formPreference">
              <Form.Label>문의 사업(복수 선택 가능)</Form.Label>
              <div className="checkbox-group">
                <Form.Check
                  type="checkbox"
                  label="마인즈 내비"
                  value="마인즈 내비"
                  onChange={(e) => {
                    const value = e.target.value;
                    const isChecked = e.target.checked;
                    const newPreferences = isChecked
                      ? [...values.preference, value]
                      : values.preference.filter((pref) => pref !== value);
                    setFieldValue("preference", newPreferences);
                  }}
                  checked={values.preference.includes("마인즈 내비")}
                  isInvalid={!!errors.preference}
                  feedback={errors.preference}
                  feedbackType="invalid"
                />
                <Form.Check
                  type="checkbox"
                  label="치유 포레스트"
                  value="치유 포레스트"
                  onChange={(e) => {
                    const value = e.target.value;
                    const isChecked = e.target.checked;
                    const newPreferences = isChecked
                      ? [...values.preference, value]
                      : values.preference.filter((pref) => pref !== value);
                    setFieldValue("preference", newPreferences);
                  }}
                  checked={values.preference.includes("치유 포레스트")}
                  isInvalid={!!errors.preference}
                  feedback={errors.preference}
                  feedbackType="invalid"
                />
                <Form.Check
                  type="checkbox"
                  label="치유 심리상담"
                  value="치유 심리상담"
                  onChange={(e) => {
                    const value = e.target.value;
                    const isChecked = e.target.checked;
                    const newPreferences = isChecked
                      ? [...values.preference, value]
                      : values.preference.filter((pref) => pref !== value);
                    setFieldValue("preference", newPreferences);
                  }}
                  checked={values.preference.includes("치유 심리상담")}
                  isInvalid={!!errors.preference}
                  feedback={errors.preference}
                  feedbackType="invalid"
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.preference}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="formMessage">
              <Form.Label>문의내용</Form.Label>
              <Form.Control
                placeholder="문의내용을 입력해주세요."
                as="textarea"
                rows={3}
                name="message"
                value={values.message}
                onChange={handleChange}
                isInvalid={!!errors.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors.message}
              </Form.Control.Feedback>
              <p>
                (주)마인즈에이아이는 문의사항 답변처리를 위해 최소한의
                개인정보(이름, 핸드폰, e-mail)만을 수집합니다. <br />
                수집된 개인정보는 다른 어떤 목적과 용도로도 사용되지 않습니다.
              </p>
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="formTerms">
            <Form.Check
              required
              name="terms"
              label="개인정보 수집에 동의합니다."
              onChange={handleChange}
              isInvalid={!!errors.terms}
              feedback={errors.terms}
              feedbackType="invalid"
            />
          </Form.Group>

          <Button type="submit">제출하기</Button>
        </Form>
      )}
    </Formik>
  );
}

export default MyValidation;
