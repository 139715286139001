import React, { useState } from "react";
import Modal from "react-modal";
import "./Footer.css";
import logowhite from "@asset/logo/logo_MindsAI_white.png";
import btn_blog from "@asset/button/btn_blog.png";
import btn_email from "@asset/button/btn_email.png";
import btn_youtube from "@asset/button/btn_youtube.png";

Modal.setAppElement("#root"); // 접근성 문제 방지

export default function Footer({ language }) {
  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false);
  const [policyModalIsOpen, setPolicyModalIsOpen] = useState(false);

  const openTermsModal = () => {
    setTermsModalIsOpen(true);
  };

  const closeTermsModal = () => {
    setTermsModalIsOpen(false);
  };

  const openPolicyModal = () => {
    setPolicyModalIsOpen(true);
  };

  const closePolicyModal = () => {
    setPolicyModalIsOpen(false);
  };

  return (
    <footer className="footer">
      <div className="inner">
        <div className="footer_icons">
          <div>
            <img className="logo" src={logowhite} alt="MindsAi_logo_white" />
          </div>
          <div className="footer_icons_group">
            <a id="icon_youtube" href="https://www.youtube.com/@cheeu342">
              <img className="icon" src={btn_youtube} alt="btn_youtube" />
            </a>
            <a id="icon_email" href="mailto:mindsai@mindsai.co.kr">
              <img className="icon" src={btn_email} alt="btn_email" />
            </a>
            <a id="icon_blog" href="https://blog.naver.com/mycheeu">
              <img className="icon" src={btn_blog} alt="btn_blog" />
            </a>
          </div>
        </div>

        <div>
          {language === "kor" ? (
            <div>
              <ul className="f1">
                <li
                  className="popup_TermsModal"
                  style={{ cursor: "pointer" }}
                  onClick={openTermsModal}
                >
                  이용약관
                </li>
                <li className="line">|</li>
                <li
                  className="popup_PolicyModal"
                  style={{ cursor: "pointer" }}
                  onClick={openPolicyModal}
                >
                  개인정보처리방침
                </li>
              </ul>
              <ul className="f2">
                <li>상호 : 주식회사 마인즈에이아이</li>
                <li className="line">|</li>
                <li>대표자 : 석정호</li>
              </ul>
              <ul className="f3">
                <li>사업자등록증 : 180-86-01522</li>
                <li className="line">|</li>
                <li>통신판매업신고증 : 제 2020-서울강남-02788호</li>
              </ul>
              <ul className="f4">
                <li>
                  주소 : 서울특별시 강남구 도곡로 155, 3층(역삼동, 명빌딩)
                </li>
                <li className="line">|</li>
                <li>전화 : 02)6959-7193</li>
                <li className="line">|</li>
                <li>팩스 : 02)6959-7194</li>
              </ul>
              <p className="p1">
                본 사이트의 콘텐츠는 저작권의 보호를 받는 바, 무단 전재, 복사,
                배포 등을 금합니다.
              </p>
              <p className="p2">
                Copyrightⓒ2024 마인즈에이아이 All rights reserved.
              </p>
            </div>
          ) : (
            <div>
              <ul className="f1">
                <li
                  className="popup_TermsModal"
                  style={{ cursor: "pointer" }}
                  onClick={openTermsModal}
                >
                  Terms and Conditions of Use
                </li>
                <li className="line">|</li>
                <li
                  className="popup_PolicyModal"
                  style={{ cursor: "pointer" }}
                  onClick={openPolicyModal}
                >
                  Personal Information Processing Policy
                </li>
              </ul>
              <ul className="f2">
                <li>Company Name : MindsAI Co., Ltd</li>
                <li className="line">|</li>
                <li>CEO : Jeongho Seok</li>
              </ul>
              <ul className="f3">
                <li>Business Registration Certificate : 180-86-01522</li>
                <li className="line">|</li>
                <li>
                  Mail-order Business Declaration : No.2020-SEOULGANGNAM-02788
                </li>
              </ul>
              <ul className="f4">
                <li>
                  Address : #3F, 155, Dogok-ro, Gangnam-gu, Seoul, Republic of
                  Korea (Yeoksam-dong, Myung BLDG.)
                </li>
                <li className="line">|</li>
                <li>Tel. : +82 2-6959-7193</li>
                <li className="line">|</li>
                <li>Fax : +82 2-6959-7194</li>
              </ul>
              <p className="p1">
                As the contents of this site is protected by the copyright,
                unauthorized reproduction, copying, distribution, etc. are
                prohibited.
              </p>
              <p className="p2">Copyrightⓒ2024 MindsAI All rights reserved.</p>
            </div>
          )}
        </div>

        {/* 이용 약관 모달 */}

        <Modal
          id="popup"
          isOpen={termsModalIsOpen}
          onRequestClose={closeTermsModal}
          contentLabel={
            language === "kor" ? "이용 약관" : "Terms and Conditions of Use"
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "60vw",
              height: "80vh", // 모달 높이 설정
              overflow: "auto", // 스크롤 추가
              paddingTop: "100px",
              paddingBottom: "100px",
              zIndex: "9999", // z-index를 높게 설정하여 모달을 가장 위로 올림
            },
          }}
        >
          <h2 id="popup_title">
            <strong>
              {language === "kor" ? "이용 약관" : "Terms and Conditions of Use"}
            </strong>
          </h2>
          <div>
            {language === "kor" ? (
              <div id="popup_contents">
                <div id="1st">
                  <strong> 제1조 (개인정보의 처리목적) </strong>
                  <p>
                    본 이용약관은 “마인즈에이아이”(이하 "사이트")의 서비스의
                    이용조건과 운영에 관한 제반 사항 규정을 목적으로 합니다.
                  </p>
                </div>
                <div id="2nd">
                  <strong> 제2조 용어의 정의 </strong>
                  <p>
                    본 약관에서 사용되는 주요한 용어의 정의는 다음과 같습니다.
                  </p>
                  <p>
                    ① 회원 : 사이트의 약관에 동의하고 개인정보를 제공하여
                    회원등록을 한 자로서, 사이트와의 이용계약을 체결하고
                    사이트를 이용하는 이용자를 말합니다.
                    <br />
                    ② 이용계약 : 사이트 이용과 관련하여 사이트와 회원간에 체결
                    하는 계약을 말합니다.
                    <br />
                    ③ 회원 아이디(이하 "ID") : 회원의 식별과 회원의 서비스
                    이용을 위하여 회원별로 부여하는 고유한 문자와 숫자의 조합을
                    말합니다.
                    <br />
                    ④ 비밀번호 : 회원이 부여받은 ID와 일치된 회원임을 확인하고
                    회원의 권익 보호를 위하여 회원이 선정한 문자와 숫자의 조합을
                    말합니다.
                    <br />
                    ⑤ 운영자 : 서비스에 홈페이지를 개설하여 운영하는 운영자를
                    말합니다.
                    <br />⑥ 해지 : 회원이 이용계약을 해약하는 것을 말합니다.
                  </p>
                </div>
                <div id="3rd">
                  <strong> 제3조 약관 외 준칙 </strong>
                  <p>
                    운영자는 필요한 경우 별도로 운영정책을 공지 안내할 수
                    있으며, 본 약관과 운영정책이 중첩될 경우 운영정책이 우선
                    적용됩니다.
                  </p>
                </div>
                <div id="4th">
                  <strong> 제4조 이용계약 체결 </strong>
                  <p>
                    ① 이용계약은 회원으로 등록하여 사이트를 이용하려는 자의 본
                    약관 내용에 대한 동의와 가입신청에 대하여 운영자의
                    이용승낙으로 성립합니다.
                    <br />② 회원으로 등록하여 서비스를 이용하려는 자는 사이트
                    가입신청 시 본 약관을 읽고 아래에 있는 "동의합니다"를
                    선택하는 것으로 본 약관에 대한 동의 의사 표시를 합니다.
                  </p>
                </div>
                <div id="5th">
                  <strong> 제5조 서비스 이용 신청 </strong>
                  <p>
                    ① 회원으로 등록하여 사이트를 이용하려는 이용자는 사이트에서
                    요청하는 제반정보(이용자ID,비밀번호, 닉네임 등)를 제공해야
                    합니다.
                    <br />② 타인의 정보를 도용하거나 허위의 정보를 등록하는 등
                    본인의 진정한 정보를 등록하지 않은 회원은 사이트 이용과
                    관련하여 아무런 권리를 주장할 수 없으며, 관계 법령에 따라
                    처벌받을 수 있습니다.
                  </p>
                </div>
                <div id="6th">
                  <strong> 제6조 개인정보처리방침 </strong>
                  <p>
                    사이트 및 운영자는 회원가입 시 제공한 개인정보 중 비밀번호를
                    가지고 있지 않으며 이와 관련된 부분은 사이트의
                    개인정보처리방침을 따릅니다.
                    <br />
                    운영자는 관계 법령이 정하는 바에 따라 회원등록정보를 포함한
                    회원의 개인정보를 보호하기 위하여 노력합니다.
                  </p>
                  <p>
                    회원의 개인정보보호에 관하여 관계법령 및 사이트가 정하는
                    개인정보처리방침에 정한 바에 따릅니다.
                  </p>
                  <p>
                    단, 회원의 귀책 사유로 인해 노출된 정보에 대해 운영자는
                    일체의 책임을 지지 않습니다.
                    <br />
                    운영자는 회원이 미풍양속에 저해되거나 국가안보에 위배되는
                    게시물 등 위법한 게시물을 등록 · 배포할 경우 관련 기관의
                    요청이 있을 시 회원의 자료를 열람 및 해당 자료를 관련 기관에
                    제출할 수 있습니다.
                  </p>
                </div>
                <div id="7th">
                  <strong> 제7조 운영자의 의무 </strong>
                  <p>
                    ① 운영자는 이용회원으로부터 제기되는 의견이나 불만이
                    정당하다고 인정할 경우에는 가급적 빨리 처리하여야 합니다.
                    다만, 개인적인 사정으로 신속한 처리가 곤란한 경우에는 사후에
                    공지 또는 이용회원에게 쪽지, 전자우편 등을 보내는 등 최선을
                    다합니다.
                    <br />② 운영자는 계속적이고 안정적인 사이트 제공을 위하여
                    설비에 장애가 생기거나 유실된 때에는 이를 지체 없이 수리
                    또는 복구할 수 있도록 사이트에 요구할 수 있습니다. 다만,
                    천재지변 또는 사이트나 운영자에 부득이한 사유가 있는 경우,
                    사이트 운영을 일시 정지할 수 있습니다.
                  </p>
                </div>
                <div id="8th">
                  <strong> 제8조 회원의 의무 </strong>
                  <p>
                    ① 회원은 본 약관에서 규정하는 사항과 운영자가 정한 제반
                    규정, 공지사항 및 운영정책 등 사이트가 공지하는 사항 및 관계
                    법령을 준수하여야 하며, 기타 사이트의 업무에 방해가 되는
                    행위, 사이트의 명예를 손상하는 행위를 해서는 안 됩니다.
                    <br />
                    ② 회원은 사이트의 명시적 동의가 없는 한 서비스의 이용 권한,
                    기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를
                    담보로 제공할 수 없습니다.
                    <br />
                    ③ 이용고객은 아이디 및 비밀번호 관리에 상당한 주의를
                    기울여야 하며, 운영자나 사이트의 동의 없이 제3자에게
                    아이디를 제공하여 이용하게 할 수 없습니다.
                    <br />④ 회원은 운영자와 사이트 및 제3자의 지적 재산권을
                    침해해서는 안 됩니다.
                  </p>
                </div>
                <div id="9th">
                  <strong> 제9조 서비스 이용 시간 </strong>
                  <p>
                    ① 서비스 이용 시간은 업무상 또는 기술상 특별한 지장이 없는
                    한 연중무휴 1일 24시간을 원칙으로 합니다. <br />
                    단, 사이트는 시스템 정기점검, 증설 및 교체를 위해 사이트가
                    정한 날이나 시간에 서비스를 일시중단 할 수 있으며 예정된
                    작업으로 인한 서비스 일시 중단은 사이트의 홈페이지에 사전에
                    공지하오니 수시로 참고하시길 바랍니다.
                    <br />
                    ② 단, 사이트는 다음 경우에 대하여 사전 공지나 예고 없이
                    서비스를 일시적 혹은 영구적으로 중단할 수 있습니다.
                    <br />
                    - 긴급한 시스템 점검, 증설, 교체, 고장 혹은 오동작을
                    일으키는 경우
                    <br />
                    - 국가비상사태, 정전, 천재지변 등의 불가항력적인 사유가 있는
                    경우
                    <br />
                    - 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
                    중지한 경우
                    <br />
                    - 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이
                    있는 경우
                    <br />
                    ③ 전항에 의한 서비스 중단의 경우 사이트는 사전에 공지사항
                    등을 통하여 회원에게 통지합니다. <br />
                    단, 사이트가 통제할 수 없는 사유로 발생한 서비스의 중단에
                    대하여 사전공지가 불가능한 경우에는 사후공지로 대신합니다.
                  </p>
                </div>
                <div id="10th">
                  <strong> 제10조 서비스 이용 해지 </strong>
                  <p>
                    ① 회원이 사이트와의 이용계약을 해지하고자 하는 경우에는 회원
                    본인이 온라인을 통하여 등록해지 신청을 하여야 합니다. 한편,
                    사이트 이용 해지와 별개로 사이트에 대한 이용계약 해지는
                    별도로 하셔야 합니다.
                    <br />② 해지 신청과 동시에 사이트가 제공하는 사이트 관련
                    프로그램이 회원 관리 화면에서 자동적으로 삭제됨으로 운영자는
                    더 이상 해지신청자의 정보를 볼 수 없습니다.
                  </p>
                </div>
                <div id="11th">
                  <strong> 제11조 서비스 이용 제한 </strong>
                  <p>
                    회원은 다음 각호에 해당하는 행위를 하여서는 아니 되며 해당
                    행위를 한 경우에 사이트는 회원의 서비스 이용 제한 및 적법한
                    조치를 할 수 있으며 이용계약을 해지하거나 기간을 정하여
                    서비스를 중지할 수 있습니다.
                    <br />
                    ① 회원 가입시 혹은 가입 후 정보 변경 시 허위 내용을 등록하는
                    행위
                    <br />
                    ② 타인의 사이트 이용을 방해하거나 정보를 도용하는 행위
                    <br />
                    ③ 사이트의 운영진, 직원 또는 관계자를 사칭하는 행위
                    <br />
                    ④ 사이트, 기타 제3자의 인격권 또는 지적재산권을 침해하거나
                    업무를 방해하는 행위
                    <br />
                    ⑤ 다른 회원의 ID를 부정하게 사용하는 행위
                    <br />
                    ⑥ 다른 회원에 대한 개인정보를 그 동의 없이 수집, 저장,
                    공개하는 행위
                    <br />
                    ⑦ 범죄와 결부된다고 객관적으로 판단되는 행위
                    <br />⑧ 기타 관련 법령에 위배되는 행위
                  </p>
                </div>
                <div id="12th">
                  <strong> 제12조 게시물의 관리 </strong>
                  <p>
                    ① 사이트의 게시물과 자료의 관리 및 운영의 책임은 운영자에게
                    있습니다. 운영자는 항상 불량 게시물 및 자료에 대하여
                    모니터링을 하여야 하며, 불량 게시물 및 자료를 발견하거나
                    신고를 받으면 해당 게시물 및 자료를 삭제하고 이를 등록한
                    회원에게 주의를 주어야 합니다.
                    <br />
                    한편, 이용회원이 올린 게시물에 대해서는 게시자 본인에게
                    책임이 있으니 회원 스스로 본 이용약관에서 위배되는 게시물은
                    게재해서는 안 됩니다.
                    <br />
                    ② 정보통신윤리위원회 등 공공기관의 시정요구가 있는 경우
                    운영자는 회원의 사전동의 없이 게시물을 삭제하거나 이동 할 수
                    있습니다.
                    <br />
                    ③ 불량게시물의 판단기준은 다음과 같습니다.
                    <br />
                    - 다른 회원 또는 제3자에게 심한 모욕을 주거나 명예를
                    손상하는 내용인 경우
                    <br />
                    - 공공질서 및 미풍양속에 위반되는 내용을 유포하거나 링크
                    시키는 경우
                    <br />
                    - 불법 복제 또는 해킹을 조장하는 내용인 경우
                    <br />
                    - 영리를 목적으로 하는 광고일 경우
                    <br />
                    - 범죄와 결부된다고 객관적으로 인정되는 내용일 경우
                    <br />
                    - 다른 이용자 또는 제3자와 저작권 등 기타 권리를 침해하는
                    경우
                    <br />
                    - 기타 관계 법령에 위배된다고 판단되는 경우
                    <br />④ 사이트 및 운영자는 게시물 등에 대하여 제3자로부터
                    명예훼손, 지적재산권 등의 권리 침해를 이유로 게시중단 요청을
                    받은 경우 이를 임시로 게시 중단(전송중단)할 수 있으며,
                    게시중단 요청자와 게시물 등록자 간에 소송, 합의 기타 이에
                    준하는 관련 기관의 결정 등이 이루어져 사이트에 접수된 경우
                    이에 따릅니다.
                  </p>
                </div>
                <div id="13th">
                  <strong> 제13조 게시물의 보관 </strong>
                  <p>
                    사이트 운영자가 불가피한 사정으로 본 사이트를 중단하게 될
                    경우, 회원에게 사전 공지를 하고 게시물의 이전이 쉽도록 모든
                    조치를 하기 위해 노력합니다.
                  </p>
                </div>
                <div id="14th">
                  <strong> 제14조 게시물에 대한 저작권 </strong>
                  <p>
                    ① 회원이 사이트 내에 게시한 게시물의 저작권은 게시한
                    회원에게 귀속됩니다. 또한 사이트는 게시자의 동의 없이
                    게시물을 상업적으로 이용할 수 없습니다. 다만 비영리 목적인
                    경우는 그러하지 아니하며, 또한 서비스 내의 게재권을
                    갖습니다.
                    <br />
                    ② 회원은 서비스를 이용하여 취득한 정보를 임의 가공, 판매하는
                    행위 등 서비스에 게재된 자료를 상업적으로 사용할 수
                    없습니다.
                    <br />③ 운영자는 회원이 게시하거나 등록하는 사이트 내의
                    내용물, 게시 내용에 대해 제12조 각호에 해당한다고 판단되는
                    경우 사전통지 없이 삭제하거나 이동 또는 등록 거부할 수
                    있습니다.
                  </p>
                </div>
                <div id="15th">
                  <strong> 제15조 손해배상 </strong>
                  <p>
                    ① 본 사이트의 발생한 모든 민,형법상 책임은 회원 본인에게
                    1차적으로 있습니다.
                    <br />② 본 사이트로부터 회원이 받은 손해가 천재지변 등
                    불가항력적이거나 회원의 고의 또는 과실로 인하여 발생한
                    때에는 손해배상을 하지 않습니다.
                  </p>
                </div>
                <div id="16th">
                  <strong> 제16조 면책 </strong>
                  <p>
                    ① 운영자는 회원이 사이트의 서비스 제공으로부터 기대되는
                    이익을 얻지 못하였거나 서비스 자료에 대한 취사선택 또는
                    이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다.
                    <br />
                    ② 운영자는 본 사이트의 서비스 기반 및 타 통신업자가 제공하는
                    전기통신 서비스의 장애로 인한 경우에는 책임이 면제되며 본
                    사이트의 서비스 기반과 관련되어 발생한 손해에 대해서는
                    사이트의 이용약관에 준합니다. <br />
                    ③ 운영자는 회원이 저장, 게시 또는 전송한 자료와 관련하여
                    일체의 책임을 지지 않습니다.
                    <br />
                    ④ 운영자는 회원의 귀책 사유로 인하여 서비스 이용의 장애가
                    발생한 경우에는 책임지지 아니합니다.
                    <br />
                    ⑤ 운영자는 회원 상호 간 또는 회원과 제3자 상호 간, 기타
                    회원의 본 서비스 내외를 불문한 일체의 활동(데이터 전송, 기타
                    커뮤니티 활동 포함)에 대하여 책임을 지지 않습니다.
                    <br />
                    ⑥ 운영자는 회원이 게시 또는 전송한 자료 및 본 사이트로
                    회원이 제공받을 수 있는 모든 자료들의 진위, 신뢰도, 정확성
                    등 그 내용에 대해서는 책임지지 아니합니다.
                    <br />
                    ⑦ 운영자는 회원 상호 간 또는 회원과 제3자 상호 간에 서비스를
                    매개로 하여 물품거래 등을 한 경우에 그로부터 발생하는 일체의
                    손해에 대하여 책임지지 아니합니다.
                    <br />
                    ⑧ 운영자는 운영자의 귀책 사유 없이 회원간 또는 회원과
                    제3자간에 발생한 일체의 분쟁에 대하여 책임지지 아니합니다.
                    <br />⑨ 운영자는 서버 등 설비의 관리, 점검, 보수, 교체 과정
                    또는 소프트웨어의 운용 과정에서 고의 또는 고의에 준하는
                    중대한 과실 없이 발생할 수 있는 시스템의 장애, 제3자의
                    공격으로 인한 시스템의 장애, 국내외의 저명한 연구기관이나
                    보안 관련 업체에 의해 대응 방법이 개발되지 아니한 컴퓨터
                    바이러스 등의 유포나 기타 운영자가 통제할 수 없는 불가항력적
                    사유로 인한 회원의 손해에 대하여 책임지지 않습니다.
                  </p>
                </div>
                <strong>부칙</strong>
                <p>이 약관은 '사이트 개설일' 부터 시행합니다.</p>
              </div>
            ) : (
              <div id="popup_contents">
                <div id="1st">
                  <strong>
                    {" "}
                    Article 1 (Purpose of processing personal information){" "}
                  </strong>
                  <p>
                    The purpose of these Terms of Use is to regulate all matters
                    relating to the terms of use and operation of the services
                    of “Minds AI” (hereinafter referred to as “Site”).
                  </p>
                </div>
                <div id="2nd">
                  <strong> Article 2 Definition of Terms </strong>
                  <p>
                    The definitions of major terms used in these Terms and
                    Conditions are as follows.
                  </p>
                  <p>
                    ① Member: refers to a user who agrees to the terms and
                    conditions of the site, registers as a member by providing
                    personal information, enters into a service agreement with
                    the site, and uses the site.
                    <br />
                    ② Use Agreement: Refers to a contract concluded between the
                    site and the member in relation to the use of the site.
                    <br />
                    ③ Member ID (hereinafter “ID”): A unique combination of
                    letters and numbers assigned to each member for
                    identification and use of services.
                    <br />
                    ④ Password: This refers to a combination of letters and
                    numbers selected by the member to confirm that the member
                    matches the ID given to the member and to protect the
                    member's rights and interests.
                    <br />
                    ⑤ Operator: Refers to the operator who opens and operates a
                    homepage on the service.
                    <br />⑥ Termination: This refers to the member canceling the
                    service agreement.
                  </p>
                </div>
                <div id="3rd">
                  <strong>
                    {" "}
                    Article 3 Terms and Conditions and Other Rules{" "}
                  </strong>
                  <p>
                    If necessary, the operator may separately announce the
                    operating policy. If these terms and conditions overlap with
                    the operating policy, the operating policy will take
                    precedence.
                  </p>
                </div>
                <div id="4th">
                  <strong> Article 4 Conclusion of service agreement </strong>
                  <p>
                    ① The service agreement is established by the consent of the
                    person who registers as a member and wishes to use the site
                    to the contents of these terms and conditions and the
                    operator's approval of the application for membership.
                    <br />② Those who wish to register as members and use the
                    service must read these terms and conditions when applying
                    for membership on the site and indicate their agreement to
                    these terms and conditions by selecting “I agree” below.
                  </p>
                </div>
                <div id="5th">
                  <strong> Article 5 Application for service use </strong>
                  <p>
                    ① Users who wish to register as members and use the site
                    must provide all information requested by the site (user ID,
                    password, nickname, etc.).
                    <br />② Members who do not register their true information,
                    such as by stealing other people's information or
                    registering false information, cannot claim any rights in
                    relation to the use of the site and may be punished
                    according to relevant laws and regulations.
                  </p>
                </div>
                <div id="6th">
                  <strong>
                    {" "}
                    Article 6 Personal information processing policy{" "}
                  </strong>
                  <p>
                    The site and its operator do not have a password among the
                    personal information provided when registering as a member,
                    and related portions follow the site's personal information
                    processing policy.
                    <br />
                    The operator strives to protect members' personal
                    information, including member registration information, in
                    accordance with relevant laws and regulations.
                  </p>
                  <p>
                    The protection of members' personal information is governed
                    by relevant laws and the personal information processing
                    policy established by the site.
                  </p>
                  <p>
                    However, the operator is not responsible for any information
                    exposed due to reasons attributable to the member.
                    <br />
                    If a member registers and distributes illegal posts, such as
                    posts that are disruptive to public morals or violate
                    national security, the operator may view the member's data
                    and submit the data to the relevant institution upon request
                    from the relevant institution.
                  </p>
                </div>
                <div id="7th">
                  <strong> Article 7 Operator’s Obligations </strong>
                  <p>
                    ① If the operator deems that opinions or complaints raised
                    by users are justified, he/she must process them as quickly
                    as possible. However, if it is difficult to process quickly
                    due to personal circumstances, we will do our best to post a
                    notice or send a note or e-mail to the user.
                    <br />② In order to continuously and stably provide the
                    site, the operator may request the site to repair or restore
                    the facility without delay in the event of equipment failure
                    or loss. However, in the event of a natural disaster or
                    unavoidable reasons for the site or operator, site operation
                    may be temporarily suspended.
                  </p>
                </div>
                <div id="8th">
                  <strong> Article 8 Member Obligations </strong>
                  <p>
                    ① Members must comply with the matters stipulated in these
                    Terms and Conditions, all regulations established by the
                    operator, notices, and operating policies, as well as
                    matters announced by the site and relevant laws and
                    regulations and you must not take any actions that interfere
                    with the site's business or damage the site's reputation.
                    <br />
                    ② Members may not transfer or gift the right to use the
                    service or any other status under the service agreement to
                    another person without the explicit consent of the site, and
                    may not provide these as collateral.
                    <br />
                    ③ Customers must pay considerable attention to managing
                    their ID and password, and cannot provide their ID to a
                    third party for use without the consent of the operator or
                    site.
                    <br />④ Members must not infringe on the intellectual
                    property rights of the Operator, the Site and any third
                    party.
                  </p>
                </div>
                <div id="9th">
                  <strong> Article 9 Service usage time </strong>
                  <p>
                    ① Service usage hours are, in principle, 24 hours a day, 365
                    days a year, unless there are special business or technical
                    disruptions.
                    <br />
                    However, the site may temporarily suspend services on days
                    or times set by the site for regular system inspection,
                    expansion, and replacement, and any temporary suspension of
                    services due to scheduled work will be announced in advance
                    on the site's homepage, so please refer to this at any time.
                    <br />
                    ② However, the site may temporarily or permanently suspend
                    services without prior notice or notice in the following
                    cases.
                    <br />
                    - In case of urgent system inspection, expansion,
                    replacement, breakdown or malfunction
                    <br />
                    - In case of force majeure such as national emergency, power
                    outage, natural disaster, etc.
                    <br />
                    - In case the telecommunication service provider specified
                    in the Telecommunications Business Act suspends
                    telecommunication services
                    <br />
                    - In cases where normal service use is disrupted due to
                    excessive service use, etc.
                    <br />
                    ③ In case of service interruption pursuant to the preceding
                    paragraph, the site will notify members in advance through
                    notices, etc. <br />
                    However, if prior notice is not possible for service
                    interruption due to reasons beyond the control of the site,
                    post-notice will be provided instead.
                  </p>
                </div>
                <div id="10th">
                  <strong> Article 10 Termination of service use </strong>
                  <p>
                    ① If a member wishes to cancel the use contract with the
                    site, the member must apply for registration cancellation
                    online. Meanwhile, termination of use of the site must be
                    done separately from termination of use of the site.
                    <br />② Upon application for cancellation, the site-related
                    programs provided by the site are automatically deleted from
                    the member management screen, so the operator can no longer
                    view the cancellation applicant's information.
                  </p>
                </div>
                <div id="11th">
                  <strong> Article 11 Restrictions on service use </strong>
                  <p>
                    Members must not engage in any of the following acts. If
                    such acts are committed, the site may restrict the member's
                    use of the service, take legal action, terminate the service
                    agreement, or suspend the service for a set period of time.
                    <br />
                    ① Registering false information when registering as a member
                    or changing information after registration <br />
                    ② Interfering with others' use of the site or stealing
                    information <br />
                    ③ Impersonating a site operator, employee, or official
                    <br />
                    ④ Actions that infringe upon the moral rights or
                    intellectual property rights of the site or other third
                    parties or interfere with business operations
                    <br />
                    ⑤ Illegal use of another member's ID
                    <br />
                    ⑥ Collecting, storing and disclosing personal information
                    about other members without their consent <br />⑦ An act
                    that is objectively judged to be linked to a crime <br />⑧
                    Other acts that violate relevant laws and regulations
                  </p>
                </div>
                <div id="12th">
                  <strong> Article 12 Management of posts </strong>
                  <p>
                    ① The operator is responsible for managing and operating the
                    site's posts and materials. The operator must always monitor
                    defective posts and materials, and when defective posts and
                    materials are discovered or reported, the administrator must
                    delete the posts and materials and issue a warning to the
                    member who registered them.
                    <br />
                    Meanwhile, since the poster is responsible for the posts
                    posted by users, members must not post posts that violate
                    these Terms of Use.
                    <br />
                    ② If there is a request for correction from a public
                    institution such as the Information and Communication Ethics
                    Committee, the operator may delete or move the posting
                    without the member's prior consent.
                    <br />
                    ③ The criteria for judging defective posts are as follows.
                    <br />
                    - In case of content that severely insults or defames other
                    members or third parties
                    <br />
                    - In case of disseminating or linking to content that
                    violates public order and morals
                    <br />
                    - In case of content that promotes illegal copying or
                    hacking
                    <br />
                    - In case of the advertisement is for profit
                    <br />
                    - In case of content is objectively acknowledged to be
                    linked to a crime
                    <br />
                    - In case of infringement of copyright or other rights of
                    other users or third parties <br />
                    - In cases where it is judged to be in violation of other
                    relevant laws and regulations
                    <br />④ If the site and operator receive a request from a
                    third party to suspend posting for reasons such as
                    defamation or infringement of intellectual property rights,
                    the site and operator may temporarily suspend posting
                    (suspend transmission), and a lawsuit may be filed between
                    the requesting suspension and the person who registered the
                    posting. If an agreement or other equivalent decision by a
                    related organization is reached and received on the site, it
                    will be followed.
                  </p>
                </div>
                <div id="13th">
                  <strong> Article 13 Storage of posts </strong>
                  <p>
                    If the site operator discontinues this site due to
                    unavoidable circumstances, we will notify members in advance
                    and strive to take all measures to facilitate the transfer
                    of posts.
                  </p>
                </div>
                <div id="14th">
                  <strong> Article 14 Copyright on postings </strong>
                  <p>
                    ① The copyright of posts posted by members on the site
                    belongs to the member who posted them. Additionally, the
                    site cannot use posts commercially without the publisher's
                    consent. However, this does not apply in the case of
                    non-profit purposes, and the right to publish within the
                    service is reserved.
                    <br />
                    ② Members may not use materials posted on the service
                    commercially, including arbitrarily processing or selling
                    information acquired using the service.
                    <br />③ The operator may delete, move, or refuse
                    registration without prior notice if the operator determines
                    that the content or postings on the site posted or
                    registered by a member fall under any of the provisions of
                    Article 12.
                  </p>
                </div>
                <div id="15th">
                  <strong> Article 15 Compensation for damages </strong>
                  <p>
                    ① Responsibility under all civil and criminal laws that
                    arise from this site lies primarily with the member.
                    <br />② If damage received by a member from this site is
                    caused by force majeure such as a natural disaster or due to
                    the member's intention or negligence, no compensation will
                    be provided.
                  </p>
                </div>
                <div id="16th">
                  <strong> Article 16 Indemnification </strong>
                  <p>
                    ① The operator is exempt from liability for the member's
                    failure to obtain the expected benefits from the site's
                    services, or for damages arising from the member's choice or
                    use of service materials.
                    <br />
                    ② The operator is exempt from liability in the event of a
                    failure of this site's service base or telecommunication
                    services provided by other telecommunications companies, and
                    any damage incurred in connection with this site's service
                    base shall be subject to the site's terms of use.
                    <br />
                    ③ The operator does not assume any liability in relation to
                    materials stored, posted or transmitted by members.
                    <br />
                    ④ The operator is not responsible for any disruption in the
                    use of the service due to the member's fault.
                    <br />
                    ⑤ The operator is not responsible for any activities
                    (including data transmission and other community activities)
                    between members, between members and third parties, or
                    between members within or outside of this service.
                    <br />
                    ⑥ The operator is not responsible for the content, including
                    the authenticity, reliability, and accuracy, of any
                    materials posted or transmitted by members or any materials
                    that members may receive through this site.
                    <br />
                    ⑦ The operator is not responsible for any damages arising
                    from trade in goods between members or between members and
                    third parties through the service.
                    <br />
                    ⑧ The operator is not responsible for any disputes that
                    arise between members or between members and third parties
                    without reasons attributable to the operator.
                    <br />⑨ The operator is responsible for system failures that
                    may occur without intentional or serious negligence in the
                    process of managing, inspecting, repairing, and replacing
                    servers and other facilities, or in the process of operating
                    software, and system failures due to attacks by third
                    parties, as well as renowned research at home and abroad. We
                    are not responsible for damage to members caused by the
                    spread of computer viruses for which no response method has
                    been developed by the organization or security-related
                    company, or other force majeure reasons beyond the control
                    of the operator.
                  </p>
                </div>
                <strong>Supplementary provisions</strong>

                <p>
                  These terms and conditions are effective from the ‘site
                  opening date’.
                </p>
              </div>
            )}
          </div>
          <button id="popup_close" onClick={closeTermsModal}>
            {language === "kor" ? "확인" : "Check"}
          </button>
        </Modal>

        {/* 개인정보 처리 방침 모달 */}
        <Modal
          id="popup"
          isOpen={policyModalIsOpen}
          onRequestClose={closePolicyModal}
          contentLabel={
            language === "kor"
              ? "개인정보 처리 방침"
              : "Personal Information Processing Policy"
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              width: "60vw",
              height: "80vh", // 모달 높이 설정
              overflow: "auto", // 스크롤 추가
              paddingTop: "100px",
              paddingBottom: "100px",
              zIndex: "9999", // z-index를 높게 설정하여 모달을 가장 위로 올림
            },
          }}
        >
          <h2 id="popup_title">
            <strong>
              {language === "kor"
                ? "개인정보 처리 방침"
                : "Personal Information Processing Policy"}
            </strong>
          </h2>
          {language === "kor" ? (
            <div id="popup_contents">
              <div id="popup_subtitle">
                <p>
                  {" "}
                  마인즈에이아이(이하 ‘회사’라 한다)는 개인정보 보호법 제30조에
                  따라 정보 주체의 개인정보를 보호하고 이와 관련한 고충을
                  신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이
                  개인정보 처리지침을 수립, 공개합니다.{" "}
                </p>
              </div>
              <div id="1st">
                <strong> 제1조 (개인정보의 처리목적) </strong>
                <p>
                  회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고
                  있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며,
                  이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라
                  별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                </p>
                <p>
                  1. 홈페이지 회원 가입 및 관리
                  <br />
                  회원 가입 의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증,
                  회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인,
                  서비스 부정 이용 방지, 만 14세 미만 아동의 개인정보처리 시
                  법정대리인의 동의 여부 확인, 각종 고지·통지, 고충 처리 등을
                  목적으로 개인정보를 처리합니다.
                </p>
                <p>
                  2. 재화 또는 서비스 제공
                  <br />
                  물품 배송, 서비스 제공, 계약서 및 청구서 발송, 콘텐츠 제공,
                  맞춤서비스 제공, 본인인증, 연령인증, 요금 결제 및 정산,
                  채권추심 등을 목적으로 개인정보를 처리합니다.
                </p>
                <p>
                  3. 고충 처리
                  <br />
                  민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지,
                  처리 결과 통보 등의 목적으로 개인정보를 처리합니다.
                </p>
              </div>
              <div id="2nd">
                <strong>제2조 (개인정보의 처리 및 보유기간)</strong>
                <p>
                  ① 회사는 법령에 따른 개인정보 보유, 이용 기간 또는
                  정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유,
                  이용 기간 내에서 개인정보를 처리, 보유합니다.
                  <br />② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
                </p>
                <p>
                  1. 홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴
                  시까지
                  <br />
                  다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
                  <br />
                  1) 관계 법령 위반에 따른 수사, 조사 등이 진행 중인 경우에는
                  해당 수사, 조사 종료 시까지
                  <br />
                  2) 홈페이지 이용에 따른 채권 및 채무관계 잔존 시에는 해당
                  채권, 채무 관계 정산 시까지
                </p>
              </div>
              <div id="3rd">
                <strong>
                  제5조(이용자 및 법정대리인의 권리와 그 행사 방법)
                </strong>
                <p>
                  ① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호
                  관련 권리를 행사할 수 있습니다.
                  <br />
                  1. 개인정보 열람 요구
                  <br />
                  2. 오류 등이 있을 경우 정정 요구
                  <br />
                  3. 삭제요구
                  <br />
                  4. 처리정지 요구
                  <br />
                  ② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편,
                  모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해
                  지체없이 조치하겠습니다.
                  <br />
                  ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
                  경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를
                  이용하거나 제공하지 않습니다.
                  <br />
                  ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을
                  받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보
                  보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야
                  합니다.
                  <br />⑤ 정보주체는 개인정보 보호법 등 관계 법령을 위반하여
                  회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및
                  사생활을 침해하여서는 아니 됩니다.
                </p>
              </div>
              <div id="4th">
                <strong>제6조(처리하는 개인정보 항목)</strong>
                <p>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
                <p>
                  1. 홈페이지 회원 가입 및 관리
                  <br />
                  필수항목 : 성명, 생년월일, 아이디, 비밀번호, 주소, 전화번호,
                  성별, 이메일주소
                </p>
                <p>
                  2. 재화 또는 서비스 제공
                  <br />
                  필수항목 : 예) 성명, 생년월일, 성별, 아이디, 비밀번호,
                  전화번호, 이메일주소
                </p>
                <p>
                  3. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로
                  생성되어 수집될 수 있습니다.
                  <br />
                  IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량
                  이용기록 등
                </p>
              </div>

              <div id="5th">
                <strong>제7조(개인정보의 파기)</strong>
                <p>
                  ① 회사는 개인정보 보유 기간의 경과, 처리목적 달성 등
                  개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를
                  파기합니다. <br />
                  ② 정보주체로부터 동의받은 개인정보 보유 기간이 경과하거나
                  처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를
                  계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의
                  데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
                  <br />
                  ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                  <br />
                  1. 파기 절차
                  <br />
                  회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
                  보호책임자의 승인을 받아 개인정보를 파기합니다.
                  <br />
                  2. 파기 방법
                  <br />
                  회사는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할
                  수 없도록 로우레밸포멧(Low Level Format) 등의 방법을 이용하여
                  파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로
                  분쇄하거나 소각하여 파기합니다.
                </p>
              </div>
              <div id="6th">
                <strong>제8조(개인정보의 안전성 확보조치)</strong>
                <p>
                  회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 하고
                  있습니다.
                  <br />
                  1. 관리적 조치 : 내부관리계획 수립 및 시행, 정기적 직원 교육
                  등<br />
                  2. 기술적 조치 : 개인정보처리시스템 등의 접근 권한 관리,
                  접근통제시스템 설치, 고유 식별정보 등의 암호화, 보안프로그램
                  설치
                  <br />
                  3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제
                </p>
              </div>
              <div id="7th">
                <strong>
                  제9조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)
                </strong>
                <p>
                  ① 회사는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해
                  이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를
                  사용합니다.
                  <br />
                  ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의
                  컴퓨터 브라우저에 보내는 소량의 정보이며 이용자들의 컴퓨터
                  내의 하드디스크에 저장되기도 합니다.
                  <br />
                  가. 쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹
                  사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접근 여부,
                  등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
                  <br />
                  나. 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구 → 인터넷
                  옵션 → 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
                  있습니다.
                  <br />
                  다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이
                  발생할 수 있습니다.
                </p>
              </div>
              <div id="8th">
                <strong>제10조(개인정보 보호책임자)</strong>
                <p>
                  ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
                  개인정보 처리와 관련한 정보주체의 불만 처리 및 피해구제 등을
                  위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                </p>
                <p>
                  ▶ 개인정보 보호책임자
                  <br />
                  성명 : 석정호
                  <br />
                  직책 : 대표이사
                  <br />
                  연락처 : [전화번호:1811-7642]
                  <br />※ 개인정보 보호 담당부서로 연결됩니다.
                </p>
                <p>
                  ② 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서
                  발생한 모든 개인정보 보호 관련 문의, 불만 처리, 피해구제 등에
                  관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수
                  있습니다. 회사는 정보주체의 문의에 대해 지체없이 답변 및
                  처리해드릴 것입니다.
                </p>
              </div>
              <div id="9th">
                <strong>제11조(개인정보 열람청구)</strong>
                <p>
                  정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람
                  청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의
                  개인정보 열람 청구가 신속하게 처리되도록 노력하겠습니다.
                </p>
                <p>
                  ▶ 개인정보 열람청구 접수․처리 부서
                  <br />
                  성명 : 석정호
                  <br />
                  직책 : 대표이사
                  <br />
                  연락처 : [전화번호:1811-7642]
                </p>
              </div>
              <div id="10th">
                <strong>제12조(권익침해 구제 방법)</strong>
                <p>
                  정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제,
                  상담 등을 문의하실 수 있습니다.
                </p>
                <p>
                  ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
                  <br />
                  - 소관 업무 : 개인정보 침해사실 신고, 상담 신청
                  <br />
                  - 홈페이지 : privacy.kisa.or.kr
                  <br />
                  - 전화 : (국번없이) 118
                  <br />- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2)
                  3층 개인정보침해신고센터
                </p>
                <p>
                  ▶ 개인정보 분쟁조정위원회
                  <br />
                  - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
                  <br />
                  - 홈페이지 : www.kopico.go.kr
                  <br />
                  - 전화 : (국번없이) 1833-6972
                  <br />- 주소 : (03171)서울특별시 종로구 세종대로 209
                  정부서울청사 4층
                </p>
                <p>
                  ▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
                  <br />▶ 경찰청 사이버안전국 : 182
                  (http://cyberbureau.police.go.kr)
                </p>
              </div>
              <div id="11th">
                <strong>제13조(개인정보 처리방침 시행 및 변경)</strong>
                <p>이 개인정보 처리방침은 2020. 5. 6부터 적용됩니다.</p>
              </div>
            </div>
          ) : (
            <div id="popup_contents">
              <div id="popup_subtitle">
                <p>
                  Minds AI Co., Ltd. (hereinafter referred to as “Company”)
                  protects the personal information of information subjects in
                  accordance with Article 30 of the Personal Information
                  Protection Act to ensure that grievances related to this can
                  be dealt with quickly and smoothly, we establish and disclose
                  personal information processing guidelines as follows.
                </p>
              </div>
              <div id="1st">
                <strong>
                  {" "}
                  Article 1 (Purpose of processing personal information){" "}
                </strong>
                <p>
                  The company processes personal information for the following
                  purposes. Personal information being processed will not be
                  used for purposes other than the following, and if the purpose
                  of use changes, necessary measures will be taken, such as
                  obtaining separate consent in accordance with Article 18 of
                  the Personal Information Protection Act.
                </p>
                <p>
                  1. Website membership registration and management
                  <br />
                  Confirmation of intention to become a member, customization
                  and authentication according to the provision of membership
                  services, maintenance and management of membership,
                  confirmation of limited identity verification system search,
                  prevention of use of service management, confirmation of
                  consent of the legal representative when processing personal
                  information of persons under the age of 14, Personal
                  information is processed for the purpose of
                  requesting/notifying claims, handling grievances, etc.
                </p>
                <p>
                  2. Provision of goods or services
                  <br />
                  We process personal information for the purposes of delivering
                  goods, providing services, sending contracts and invoices,
                  providing content, providing customized services, identity
                  verification, age verification, bill payment and settlement,
                  and debt collection.
                </p>
                <p>
                  3. Grievance handling
                  <br />
                  Personal information is processed for the purposes of
                  verifying the identity of the complainant, confirming the
                  complaint, contacting and notifying for factual investigation,
                  and notifying the result of processing.
                </p>
              </div>
              <div id="2nd">
                <strong>
                  Article 2 (Processing and retention period of personal
                  information)
                </strong>
                <p>
                  ① The company processes and retains personal information
                  within the personal information retention and use period in
                  accordance with laws and regulations, or within the personal
                  information retention and use period consented to when
                  collecting personal information from the information subject.
                  <br />② The processing and retention period for each personal
                  information is as follows.
                </p>
                <p>
                  1. Website membership registration and management: Until
                  withdrawal of business/organization website
                  <br />
                  However, in the case of the following reasons, until the end
                  of the relevant reason
                  <br />
                  1) If an investigation or investigation is in progress for
                  violation of relevant laws or regulations, until the
                  investigation or investigation is completed
                  <br />
                  2) If any claims or debts remain due to use of the website,
                  until the relevant claims or debts are settled.
                </p>
              </div>
              <div id="3rd">
                <strong>
                  Article 5 (Rights of users and legal representatives and
                  methods of exercising them)
                </strong>
                <p>
                  ① The information subject may exercise the following rights
                  related to personal information protection against the company
                  at any time.
                  <br />
                  1. Request to view personal information
                  <br />
                  2. Request for correction if there is an error, etc.
                  <br />
                  3. Request for deletion
                  <br />
                  4. Request to suspend processing
                  <br />
                  ② Rights pursuant to Paragraph 1 may be exercised to the
                  Company in writing, by phone, e-mail, facsimile (FAX), etc.,
                  and the Company will take action without delay.
                  <br />
                  ③ If the information subject requests correction or deletion
                  of errors in personal information, the company will not use or
                  provide the personal information until correction or deletion
                  is completed.
                  <br />
                  ④ Rights under paragraph 1 may be exercised through an agent,
                  such as the information subject's legal representative or a
                  person authorized to do so. In this case, you must submit a
                  power of attorney in the format of Appendix 11 of the
                  Enforcement Rules of the Personal Information Protection Act.
                  <br />⑤ The information subject shall not violate the personal
                  information and privacy of the information subject or others
                  processed by the company in violation of relevant laws and
                  regulations, such as the Personal Information Protection Act.
                </p>
              </div>
              <div id="4th">
                <strong>
                  Article 6 (Personal information items processed)
                </strong>
                <p>
                  The company processes the following personal information
                  items.
                </p>
                <p>
                  1. Website membership registration and management
                  <br />
                  Required items: name, date of birth, ID, password, address,
                  phone number, gender, email address
                </p>
                <p>
                  2. Provision of goods or services
                  <br />
                  Required items: Yes. Name, date of birth, gender, ID,
                  password, phone number, email address
                </p>
                <p>
                  3. The following personal information items may be
                  automatically created and collected during the use of Internet
                  services.
                  <br />
                  IP address, cookie, MAC address, service use record, visit
                  record, bad use record, etc.
                </p>
              </div>
              <div id="5th">
                <strong>Article 7 (Destruction of personal information)</strong>
                <p>
                  ① When personal information becomes unnecessary, such as when
                  the personal information retention period has passed or the
                  purpose of processing has been achieved, the company destroys
                  the relevant personal information without delay.
                  <br />
                  ② If personal information must continue to be preserved
                  pursuant to other laws and regulations despite the expiration
                  of the personal information retention period consented to by
                  the information subject or the purpose of processing has been
                  achieved, the personal information shall be transferred to a
                  separate database (DB) or stored in another storage location.
                  Preserve.
                  <br />
                  ③ The procedures and methods for destroying personal
                  information are as follows.
                  <br />
                  1. Destruction Procedure
                  <br />
                  The company selects the personal information that requires
                  destruction and destroys the personal information with the
                  approval of the company's personal information protection
                  manager.
                  <br />
                  2. Method of destruction
                  <br />
                  The company destroys personal information recorded and stored
                  in the form of electronic files using methods such as low
                  level format so that the records cannot be reproduced, and
                  personal information recorded and stored in paper documents is
                  destroyed by shredding or incineration.
                </p>
              </div>
              <div id="6th">
                <strong>
                  Article 8 (Measures to ensure the safety of personal
                  information)
                </strong>
                <p>
                  The company is taking the following measures to ensure the
                  safety of personal information.
                  <br />
                  1. Management measures: Establishment and implementation of
                  internal management plan, regular employee training, etc.
                  <br />
                  2. Technical measures: Management of access rights to personal
                  information processing systems, installation of access control
                  systems, encryption of unique identification information,
                  etc., installation of security programs.
                  <br />
                  3. Physical measures: Access control to computer rooms, data
                  storage rooms, etc.
                </p>
              </div>
              <div id="7th">
                <strong>
                  Article 9 (Matters regarding installation, operation and
                  refusal of automatic personal information collection devices)
                </strong>
                <p>
                  ① The company uses ‘cookies’ to store usage information and
                  retrieve it from time to time in order to provide
                  individualized services to users.
                  <br />
                  ② Cookies are a small amount of information that the server
                  (http) used to run the website sends to the user's computer
                  browser and are sometimes stored on the hard disk of the
                  user's computer.
                  <br />
                  A. Purpose of use of cookies: They are used to provide
                  optimized information to users by identifying visitation and
                  usage patterns, popular search terms, secure access, etc. for
                  each service and website visited by the user.
                  <br />
                  B. Installation, operation and refusal of cookies: You can
                  refuse the storage of cookies through the option settings in
                  the Tools → Internet Options → Personal Information menu at
                  the top of the web browser.
                  <br />
                  C. If you refuse to store cookies, you may have difficulty
                  using customized services.
                </p>
              </div>
              <div id="8th">
                <strong>
                  Article 10 (Personal Information Protection Manager)
                </strong>
                <p>
                  ① The company is responsible for overall management of
                  personal information processing, and has designated a personal
                  information protection manager as follows to handle complaints
                  and provide relief for damage from information subjects
                  related to personal information processing.
                </p>
                <p>
                  ▶ Personal information protection officer
                  <br />
                  Name: Seok Jeong-ho
                  <br />
                  Position: CEO
                  <br />
                  Contact information: [Phone number: 1811-7642]
                  <br />※ You will be connected to the personal information
                  protection department.
                </p>
                <p>
                  ② Information subjects may inquire about all personal
                  information protection-related inquiries, complaint handling,
                  damage relief, etc. that arise while using the company's
                  services (or business) to the personal information protection
                  manager and responsible department. The company will respond
                  and process inquiries from information subjects without delay.
                </p>
              </div>
              <div id="9th">
                <strong>
                  Article 11 (Request to view personal information)
                </strong>
                <p>
                  The information subject may request access to personal
                  information pursuant to Article 35 of the Personal Information
                  Protection Act to the department below. The company will
                  strive to promptly process the information subject's request
                  to view personal information.
                </p>
                <p>
                  ▶ Personal information access request reception and
                  processing department
                  <br />
                  Name: Seok Jeong-ho
                  <br />
                  Position: CEO
                  <br />
                  Contact information: [Phone number: 1811-7642]
                </p>
              </div>
              <div id="10th">
                <strong>
                  Article 12 (Methods for relief from rights infringement)
                </strong>
                <p>
                  Information subjects can inquire about damage relief and
                  consultation regarding personal information infringement to
                  the organizations below.
                </p>
                <p>
                  ▶ Personal Information Infringement Report Center (operated
                  by Korea Internet & Security Agency)
                  <br />
                  - Responsibilities: Reporting personal information
                  infringement, requesting consultation
                  <br />
                  - Homepage : privacy.kisa.or.kr
                  <br />
                  - Phone: (without area code) 118
                  <br />- Address: Personal Information Infringement Reporting
                  Center, 3rd floor, 9 Jinheung-gil, Naju-si, Jeollanam-do
                  (58324) (301-2, Bitgaram-dong)
                </p>
                <p>
                  ▶ Personal Information Dispute Mediation Committee
                  <br />
                  - Responsibilities: Request for personal information dispute
                  mediation, group dispute mediation (civil resolution)
                  <br />
                  - Homepage : www.kopico.go.kr
                  <br />
                  - Phone: (without area code) 1833-6972
                  <br />- Address: (03171) 4th floor, Seoul Government Complex,
                  209 Sejong-daero, Jongno-gu, Seoul
                </p>
                <p>
                  ▶ Supreme Prosecutors' Office Cyber ​​Crime Investigation
                  Team: 02-3480-3573 (www.spo.go.kr)
                  <br />▶ National Police Agency Cyber ​​Security Bureau: 182
                  (http://cyberbureau.police.go.kr)
                </p>
              </div>
              <div id="11th">
                <strong>
                  Article 13 (Implementation and change of personal information
                  processing policy)
                </strong>
                <p>This privacy policy is effective from May 6, 2020.</p>
              </div>
            </div>
          )}
          <button id="popup_close" onClick={closePolicyModal}>
            {language === "kor" ? "확인" : "Check"}
          </button>
        </Modal>
      </div>
    </footer>
  );
}
