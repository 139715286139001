import React, { useState } from "react";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css"; // Swiper 스타일을 가져옵니다.
import "swiper/css/bundle";
import "swiper/css";

import "./CheeuCounsel.css";

import TopButton from "../components_library/TopButton";

import ic_cheeucounsel from "@asset/icon/ic_cheeucounsel.png";

export default function CheeuCounsel() {
  return (
    <div className="cheeucounsel">
      <TopButton />
      <div className="cc_main_bg">
        <div className="inner">
          <div className="cc_title">
            <img id="cc_logo" src={ic_cheeucounsel} alt="ic_cheeucounsel" />
            <p id="cc_title_01">「내담자 중심의 개인맞춤 심리상담」</p>
            <h1 id="cc_title_02">MINDSAI 심리상담센터 '치유'</h1>
          </div>

          <div className="cc_subtitle">
            <h1 id="cc_subtitle_01">치유 심리상담센터</h1>
            <p id="cc_subtitle_02">
              {" "}
              치유심리상담센터는 내담자를 가장 먼저 생각합니다.
            </p>
            <p id="cc_subtitle_03">
              {" "}
              강남세브란스 공식 협력기관이며, 본사 심리상담소인
              '치유심리상담센터'에서는 <br />
              공인된 심리전문가들이 전문적인 심리상담서비스를 운영하고 있으며,{" "}
              <br />
              마인즈 내비와 치유 포레스트 프로그램까지 통합 서비스를 제공하고
              있습니다.
            </p>
            <a href="https://www.cheeu.co.kr/cheeu">
              <button id="btn_apply">상담 신청하기</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
