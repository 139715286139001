// AccordionComponent.js 파일
import React from "react";
import { Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./MyAccordion.css";

function MyAccordion() {
  return (
    <div className="MyAccordion">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="history_year">
              <span>20</span>24
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="history_group" id="history_2024">
              <div className="history_list">
                <h1 className="history_month">01</h1>
                <ul className="history_2024_01">
                  <li className="history_contents">
                    {" "}
                    Obtained the approval of the confirmatory clinical trial for
                    SaMD medical devices for depression diagnosis
                  </li>
                  <li className="history_contents">
                    {" "}
                    Acquired Innobiz Certification
                  </li>
                  <li className="history_contents">
                    {" "}
                    Established the nationwide network of the cooperative
                    psychological counseling center
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">04</h1>
                <ul className="history_2024_04">
                  <li className="history_contents">
                    Obtained the approval of the confirmatory clinical trial for
                    SaMD medical devices for depression therapy
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">07</h1>
                <ul className="history_2024_07">
                  <li className="history_contents">
                    For PROVE, obtained Psychological Test Certification by
                    Korean Psychological Association
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">08</h1>
                <ul className="history_2024_08">
                  <li className="history_contents">
                    {" "}
                    Obtained the approval of the confirmatory clinical trial for
                    SaMD medical devices for mild cognitive impairment diagnosis{" "}
                  </li>
                  <li className="history_contents">
                    {" "}
                    Won Best 10 with 'Minds. NAVI' at the 2024 Meditech Awards
                  </li>
                  <li className="history_contents">
                    {" "}
                    Won the Excellent Award with 'CHEEU. Forest', 'Varabom-D' at
                    the 2024 Meditech Awards
                  </li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="history_year">
              <span>20</span>23
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="history_group" id="history_2023">
              <div className="history_list">
                <h1 className="history_month">05</h1>
                <ul className="history_2023_05">
                  <li className="history_contents">
                    {" "}
                    <span id="colored">
                      Obtained the approval of the confirmatory clinical trial
                      for the elderly cognitive function diagnosis program (DTx
                      medical device)
                    </span>
                  </li>
                  <li className="history_contents">
                    {" "}
                    Selected as one of the 200 Baby Unicorn Companies by
                    Ministry of SMEs and Startups
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">08</h1>
                <ul className="history_2023_08">
                  <li className="history_contents">
                    Acquired GMP Certification for medical devices
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">09</h1>
                <ul className="history_2023_09">
                  <li className="history_contents">
                    Acquired ISO 13485 Certification
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">12</h1>
                <ul className="history_2023_12">
                  <li className="history_contents">
                    Obtained the approval of the confirmatory clinical trial for
                    DTx medical devices for depression therapy
                  </li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <div className="history_year">
              <span>20</span>22
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="history_group" id="history_2022">
              <div className="history_list">
                <h1 className="history_month">01</h1>
                <ul className="history_2022_01">
                  <li className="history_contents">
                    <span id="colored"> Acquired </span> DTx Program{" "}
                    <span id="colored">
                      {" "}
                      GS (Software Quality Certification) Grade 1 for
                    </span>{" "}
                    depression therapy
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">02</h1>
                <ul className="history_2022_02">
                  <li className="history_contents">
                    Selected as one of the 1000 National Representative
                    Innovative Companies (Ministry of Health & Welfare)
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">07</h1>
                <ul className="history_2022_07">
                  <li className="history_contents">
                    <span id="colored">
                      Obtained Technical Assessment Grade T-3 Certification
                    </span>
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">08</h1>
                <ul className="history_2022_08">
                  <li className="history_contents">
                    Attracted investment in Series A
                  </li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <div className="history_year">
              <span>20</span>21
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="history_group" id="history_2021">
              <div className="history_list">
                <h1 className="history_month">02</h1>
                <ul className="history_2021_02">
                  <li className="history_contents">
                    <span id="colored">
                      Acquired the Certification of Venture Company Confirmation
                    </span>
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">08</h1>
                <ul className="history_2021_08">
                  <li className="history_contents">
                    Attracted investment in SEED
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">11</h1>
                <ul className="history_2021_11">
                  <li className="history_contents">
                    Obtained the approval of the confirmatory clinical trial for
                    SaMD medical devices for depression diagnosis
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">12</h1>
                <ul className="history_2021_12">
                  <li className="history_contents">
                    Obtained the approval of the confirmatory clinical trial for
                    DTx medical devices for depression therapy
                  </li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <div className="history_year">
              <span>20</span>20
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="history_group" id="history_2020">
              <div className="history_list">
                <h1 className="history_month">05</h1>
                <ul className="history_2020_05">
                  <li className="history_contents">
                    <span id="colored">
                      'Opened 'CHEEU Psychological Counseling Center'
                      <br />
                      Selected as the First Penguin Company by Korea Credit
                      Guarantee Fund
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            <div className="history_year">
              <span>20</span>19
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="history_group" id="history_2019">
              <div className="history_list">
                <h1 className="history_month">11</h1>
                <ul className="history_2019_11">
                  <li className="history_contents">Established the company</li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default MyAccordion;
