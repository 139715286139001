import React, { useState } from "react";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css"; // Swiper 스타일을 가져옵니다.
import "swiper/css/bundle";
import "swiper/css";

import "./Home.css";

import Accordion from "../components_library/MyAccordion.js";
import TopButton from "../components_library/TopButton.js";
import ControlledCarousel_youtube from "../components_library/ControlledCarousel_youtube.js";
// import CesPopup from "../ces/CesPopup";

import News from "../components_library/News.js";

import ic_main from "@asset/icon/ic_main_video.png";
import logo_kor from "@asset/logo/logo_kor.png";

import ourmission from "@asset/image/img_ourmission.png";

// partners image
import img_partners01 from "@asset/partners/img_partners01.png";
import img_partners02 from "@asset/partners/img_partners02.png";
import img_partners03 from "@asset/partners/img_partners03.png";
import img_partners04 from "@asset/partners/img_partners04.png";
import img_partners05 from "@asset/partners/img_partners05.png";

import img_partners06 from "@asset/partners/img_partners06.png";
import img_partners07 from "@asset/partners/img_partners07.png";
import img_partners08 from "@asset/partners/img_partners08.png";
import img_partners09 from "@asset/partners/img_partners09.png";
import img_partners10 from "@asset/partners/img_partners10.png";

import img_partners11 from "@asset/partners/img_partners11.png";
import img_partners12 from "@asset/partners/img_partners12.png";
import img_partners13 from "@asset/partners/img_partners13.png";
import img_partners14 from "@asset/partners/img_partners14.png";
import img_partners15 from "@asset/partners/img_partners15.png";

import img_partners16 from "@asset/partners/img_partners16.png";
import img_partners17 from "@asset/partners/img_partners17.png";
import img_partners18 from "@asset/partners/img_partners18.png";
import img_partners19 from "@asset/partners/img_partners19.png";
import img_partners20 from "@asset/partners/img_partners20.png";

import img_partners21 from "@asset/partners/img_partners21.png";
import img_partners22 from "@asset/partners/img_partners22.png";
import img_partners23 from "@asset/partners/img_partners23.png";
import img_partners24 from "@asset/partners/img_partners24.png";

export default function Home() {
  const [category, setCategory] = useState("");
  // const [showPopup, setShowPopup] = useState(true);

  // const handleCategoryChange = (event) => {
  //   setCategory(event.target.value);
  // };

  // const handleClosePopup = () => {
  //   setShowPopup(false);
  // };

  return (
    <div>
      <TopButton />
      <div className="home">
        {/* {showPopup && <CesPopup onClose={handleClosePopup} />} */}
        <div className="mainvideo">
          <div className="inner">
            <img id="mv_ic" src={ic_main} alt="ic_main" />
            <h1 id="mv_title">
              바이오 기술과 인공지능의 융합,
              <br />
              정신건강 서비스 전문기업
            </h1>
            <img id="mv_logo_kor" src={logo_kor} alt="logo_kor" />
          </div>
        </div>

        <div className="ourmission">
          <div className="inner">
            <h1 id="title-1">Our Mission</h1>
            <p id="title-2">
              마인즈에이아이는 마음의 병에 대한 대중의 인식을 전환하고,
              예방관리에 대한 접근성을 세심하게 배려함으로써 <br />
              정신건강 전문 서비스에 대한 진입장벽을 낮추기 위해 많은 노력을
              기울여왔습니다.
            </p>
            <h3 id="subtitle-1">
              정신건강을 위한 원스톱(One Stop) 통합 솔루션 제공 전문기업,
              마인즈에이아이
            </h3>
            <p id="subtitle-2">
              현대인의 정신건강을 책임지고, <br />
              나아가 인류의 건강한 정신과 삶의 질 향상에 기여하겠습니다.
              <br />
              ESG 원칙을 따르고 지속가능한 경영을 실천하겠습니다.{" "}
            </p>
            <img id="img_ourmission" src={ourmission} alt="ourmission" />
            {/* <div id="btn_download_normal"></div> */}
          </div>
        </div>

        <div className="business">
          <div className="inner">
            <div className="title">비지니스</div>

            <div className="business_contents">
              <div
                className="business_contents_inner"
                id="business_contents_01"
              >
                <p className="business_contents_title">01</p>
                <p className="business_contents_subtitle">
                  정신건강 분석평가 프로그램 Minds. NAVI 마인즈 내비
                </p>
                <p className="business_contents_text">
                  언제 어디서나 부담없이 PROVE Test와 타액 호르몬 분석 키트를
                  활용하면 과학적이고 정밀한 통합분석(심리지표+신체지표)을 통해
                  자신의 우울 증상과 스트레스 상태를 알아볼 수 있습니다.
                </p>
              </div>
              <div
                className="business_contents_inner"
                id="business_contents_02"
              >
                <p className="business_contents_title">02</p>
                <p className="business_contents_subtitle">
                  정신건강 VR 평가·교육·훈련 프로그램 CHEEU. Forest 치유
                  포레스트
                </p>
                <p className="business_contents_text">
                  정신건강의 기초체력을 키우고, 우울증과 스트레스 관리,
                  정서조절에 도움을 주는 VR 평가·교육·훈련 프로그램을 통해
                  꾸준한 정신건강 관리를 할 수 있습니다.
                </p>
              </div>
              <div
                className="business_contents_inner"
                id="business_contents_03"
              >
                <p className="business_contents_title">03</p>
                <p className="business_contents_subtitle">
                  개인맞춤 심리상담 CHEEU. Counsel 치유 심리상담
                </p>
                <p className="business_contents_text">
                  공인된 심리상담가들의 전문 상담을 통해 보다 근본적인 마음의
                  문제를 해결하고, 몸과 마음이 온전히 건강하도록 치유할 수
                  있습니다.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="history">
          <div className="inner">
            <div className="title">연혁</div>
            <Accordion />
          </div>
        </div>

        <div className="partners">
          <div className="title">Partners</div>
          <div className="inner">
            <p className="partners_subtitle">[ 공공 ]</p>
            <div className="partners_logo" id="public_logo">
              <img src={img_partners01} /> <img src={img_partners02} />{" "}
              <img src={img_partners03} />
              <img src={img_partners04} /> <img src={img_partners05} />{" "}
              <img src={img_partners18} />
              <img src={img_partners19} /> <img src={img_partners20} />{" "}
              <img src={img_partners21} />
              <img src={img_partners22} />
            </div>

            <p className="partners_subtitle">[ 의료 ]</p>
            <div className="partners_logo" id="medical_logo">
              <img src={img_partners06} /> <img src={img_partners07} />{" "}
              <img src={img_partners08} />
              <img src={img_partners09} /> <img src={img_partners10} />{" "}
              <img src={img_partners11} />
              <img src={img_partners12} /> <img src={img_partners13} />{" "}
              <img src={img_partners14} />
              <img src={img_partners15} /> <img src={img_partners16} />{" "}
              <img src={img_partners17} />
            </div>

            <p className="partners_subtitle">[ 기업 ]</p>
            <div className="partners_logo" id="company_logo">
              <img src={img_partners23} /> <img src={img_partners24} />
            </div>
          </div>
        </div>

        <div className="media">
          <div className="inner">
            <a href="https://www.youtube.com/@cheeu342">
              <button id="btn-more-video">영상 더보기</button>
            </a>
            <div className="title">미디어</div>
            <ControlledCarousel_youtube />
          </div>
        </div>

        <div className="news">
          <div className="title">뉴스</div>
          <News />
        </div>
      </div>
    </div>
  );
}
