// AccordionComponent.js 파일
import React from "react";
import { Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "./MyAccordion.css";

function MyAccordion() {
  return (
    <div className="MyAccordion">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <div className="history_year">
              <span>20</span>24
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="history_group" id="history_2024">
              <div className="history_list">
                <h1 className="history_month">01</h1>
                <ul className="history_2024_01">
                  <li className="history_contents">
                    {" "}
                    우울증 진단 SaMD 의료기기 확증임상시험 승인
                  </li>
                  <li className="history_contents"> 이노비스인증 획득</li>
                  <li className="history_contents">
                    {" "}
                    협력 심리상담센터 전국망 구축
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">04</h1>
                <ul className="history_2024_04">
                  <li className="history_contents">
                    우울증 치료 SaMD 의료기기 확증임상시험 승인
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">07</h1>
                <ul className="history_2024_07">
                  <li className="history_contents">
                    PROVE, (사)한국심리학회 심리검사 인증
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">08</h1>
                <ul className="history_2024_08">
                  <li className="history_contents">
                    {" "}
                    경도인지장애 진단 SaMD 의료기기 확증임상시험 승인
                  </li>
                  <li className="history_contents">
                    {" "}
                    2024 메디테크 어워즈 'Minds. NAVI' Best 10 수상
                  </li>
                  <li className="history_contents">
                    {" "}
                    2024 메디테크 어워즈 'CHEEU. Forest', 'Varabom-D' Excellent
                    수상
                  </li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div className="history_year">
              <span>20</span>23
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="history_group" id="history_2023">
              <div className="history_list">
                <h1 className="history_month">05</h1>
                <ul className="history_2023_05">
                  <li className="history_contents">
                    {" "}
                    <span id="colored">
                      고령자 인지기능 진단 프로그램(DTx 의료기기) 탐색임상시험
                      승인
                    </span>
                  </li>
                  <li className="history_contents">
                    {" "}
                    중소벤처기업부 아기유니콘 200 기업 선정
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">08</h1>
                <ul className="history_2023_08">
                  <li className="history_contents">의료기기 GMP 인증 획득</li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">09</h1>
                <ul className="history_2023_09">
                  <li className="history_contents">ISO 13485 인증 획득</li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">12</h1>
                <ul className="history_2023_12">
                  <li className="history_contents">
                    우울증 치료 DTx 의료기기 확증임상시험 승인
                  </li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <div className="history_year">
              <span>20</span>22
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="history_group" id="history_2022">
              <div className="history_list">
                <h1 className="history_month">01</h1>
                <ul className="history_2022_01">
                  <li className="history_contents">
                    우울증 치료 DTx{" "}
                    <span id="colored">
                      프로그램 GS(소프트웨어품질인증) 1등급 획득
                    </span>
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">02</h1>
                <ul className="history_2022_02">
                  <li className="history_contents">
                    혁신기업 국가대표 1000 기업 선정(보건복지부)
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">07</h1>
                <ul className="history_2022_07">
                  <li className="history_contents">
                    <span id="colored">기술평가 T-3 등급 인증</span>
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">08</h1>
                <ul className="history_2022_08">
                  <li className="history_contents">시리즈A 투자유치</li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <div className="history_year">
              <span>20</span>21
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="history_group" id="history_2021">
              <div className="history_list">
                <h1 className="history_month">02</h1>
                <ul className="history_2021_02">
                  <li className="history_contents">
                    <span id="colored">벤처기업확인서 인증 획득</span>
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">08</h1>
                <ul className="history_2021_08">
                  <li className="history_contents">SEED 투자유치 완료</li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">11</h1>
                <ul className="history_2021_11">
                  <li className="history_contents">
                    우울증 진단 SaMD 의료기기 탐색임상시험 승인
                  </li>
                </ul>
              </div>

              <div className="history_list">
                <h1 className="history_month">12</h1>
                <ul className="history_2021_12">
                  <li className="history_contents">
                    우울증 치료 DTx 의료기기 탐색임상시험 승인
                  </li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <div className="history_year">
              <span>20</span>20
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="history_group" id="history_2020">
              <div className="history_list">
                <h1 className="history_month">05</h1>
                <ul className="history_2020_05">
                  <li className="history_contents">
                    <span id="colored">
                      '치유심리상담센터' 개소
                      <br />
                      신용보증기금 퍼스트펭귄형 기업 선정
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            <div className="history_year">
              <span>20</span>19
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="history_group" id="history_2019">
              <div className="history_list">
                <h1 className="history_month">11</h1>
                <ul className="history_2019_11">
                  <li className="history_contents">회사설립</li>
                </ul>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default MyAccordion;
