import React, { useState } from "react";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css"; // Swiper 스타일을 가져옵니다.
import "swiper/css/bundle";
import "swiper/css";

import "./CheeuCounselEng.css";

import TopButton from "../components_library/TopButton";

import ic_cheeucounsel from "@asset/icon/ic_cheeucounsel.png";

export default function CheeuCounselEng() {
  return (
    <div className="cheeucounselEng">
      <TopButton />
      <div className="cc_main_bg">
        <div className="inner">
          <div className="cc_title">
            <img id="cc_logo" src={ic_cheeucounsel} alt="ic_cheeucounsel" />
            <p id="cc_title_01">
              「Client-centered Customized Psychological Counseling」
            </p>
            <h1 id="cc_title_02">
              MINDSAI Psychological Counseling Center 'CHEEU'
            </h1>
          </div>

          <div className="cc_subtitle">
            <h1 id="cc_subtitle_01">CHEEU Psychological Counseling Center</h1>
            <p id="cc_subtitle_02">
              {" "}
              CHEEU Psychological Counseling Center puts clients first.
            </p>
            <p id="cc_subtitle_03">
              {" "}
              As an official partner of Gangnam Severance Hospital, the
              psychological counseling center in our headquarters, 'CHEEU
              Psychological Counseling Center' operates professional
              psychological counseling services by certified psychological
              experts and provides integrated services from Minds. NAVI to
              CHEEU. Forest Program.
            </p>
            <a href="https://www.cheeu.co.kr/cheeu">
              <button id="btn_apply">Apply for Counseling</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
