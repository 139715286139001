import React, { useState } from "react";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css"; // Swiper 스타일을 가져옵니다.
import "swiper/css/bundle";
import "swiper/css";

import "./CheeuForestEng.css";

import TopButton from "../components_library/TopButton";

import ic_cheeuforest from "@asset/icon/ic_cheeuforest.png";
import logoset_w from "@asset/image/img_logoset_white.png";
import logoset from "@asset/image/img_logoset_colored.png";

import cheeuforest_service1 from "@asset/image/img_cheeuforest_service1.png";
import cheeuforest_service2 from "@asset/image/img_cheeuforest_service2.png";
import cheeuforest_service3 from "@asset/image/img_cheeuforest_service3.png";
import cheeuforest_service4 from "@asset/image/img_cheeuforest_service4.png";

import cheeuforest_target1 from "@asset/image/img_cheeuforest_target1.png";
import cheeuforest_target2 from "@asset/image/img_cheeuforest_target2.png";
import cheeuforest_target3 from "@asset/image/img_cheeuforest_target3.png";

import cheeuforest_kiosk from "@asset/image/img_cheeuforest_kiosk.png";

import ControlledCarousel from "../components_library/ControlledCarousel";

export default function CheeuForestEng() {
  return (
    <div className="cheeuforestEng">
      <TopButton />
      <div className="cf_main_bg">
        <div className="inner">
          <div className="cf_title">
            <img id="cf_logo" src={ic_cheeuforest} alt="ic_cheeuforest" />
            <h1 id="cf_title_02">
              A mental health VR assessment-education-training program
            </h1>
            <div id="cf_title_01">
              <p>
                for emotional regulation training and the management of
                depression and stress which are a disease of modern people’s
                mind
              </p>
            </div>
          </div>

          <div className="cf_subtitle">
            <h1 id="cf_subtitle_01">CHEEU. Forest Program</h1>
            <p id="cf_subtitle_02">
              {" "}
              Selected as 「Knowledge Service Industry Core Technology
              Development Project by Ministry of Trade, Industry and Energy」
              developed through National R&D Project
            </p>
            <p id="cf_subtitle_03">
              {" "}
              CHEEU. Forest that medical professionals, psychological counselors
              and the MindsAI R&D center have developed together is a program
              that has secured professionalism and reliability by completing
              clinical trials.{" "}
            </p>
            <p id="cf_subtitle_04">
              {" "}
              *Registered the copyright for 「CHEEU. Forest」{" "}
            </p>
            <div className="cf_sub_logo">
              <img id="cf_logoset_w" src={logoset_w} alt="logoset" />
              <img id="cf_logoset" src={logoset} alt="logoset" />
            </div>
          </div>
        </div>
      </div>

      <div className="cf_service">
        <div className="inner">
          <div className="title">Service</div>
          <div className="text">
            <h1 id="cf_service_title">CHEEU. Forest</h1>
            <p id="cf_service_subtitle">
              {" "}
              CHEEU. Forest improves the quality of your life with systematic
              and differentiated mental health management.
            </p>
          </div>
          <div className="cf_service_contents">
            <figure className="cf_service_list" id="cf_service_list_01">
              <div className="cheeuforest_service_img">
                <img src={cheeuforest_service1} alt="cheeuforest_service1" />
              </div>
              <div className="cf_service_list_text">
                <h1 className="cf_service_list_title">Reliability</h1>
                <h3 className="cf_service_list_subtitle">
                  The reliable services are provided through objective and
                  standardized assessment, education and training services
                  provided after the review of the professional medial team from
                  Psychiatry Department, Gangnam Severance Hospital.
                </h3>
              </div>
            </figure>

            <figure className="cf_service_list" id="cf_service_list_02">
              <div className="cheeuforest_service_img">
                <img src={cheeuforest_service2} alt="cheeuforest_service2" />
              </div>
              <div className="cf_service_list_text">
                <h1 className="cf_service_list_title">Effectiveness</h1>
                <h3 className="cf_service_list_subtitle" id="special">
                  It is effective in improving the emotional control and
                  mentalization ability of persons with depressive symptoms and
                  is helpful in maintaining health and improving the
                  understanding of mental health.
                </h3>
                <p className="cf_service_list_body">
                  #As depression scores and suicide risk scores were
                  significantly reduced, the clinical trial results proved that
                  the program was effective.
                </p>
              </div>
            </figure>

            <figure className="cf_service_list" id="cf_service_list_03">
              <div className="cf_service_list_img">
                <img src={cheeuforest_service3} alt="cheeuforest_service3" />
              </div>
              <div className="cf_service_list_text">
                <h1 className="cf_service_list_title">Convenience</h1>
                <h3 className="cf_service_list_subtitle">
                  It is easy to access in the form of a kiosk available for VR
                  operation and is possible to continuously conduct
                  self-training, allowing systematic mental health management.
                </h3>
              </div>
            </figure>

            <figure className="cf_service_list" id="cf_service_list_04">
              <div className="cf_service_list_img">
                <img src={cheeuforest_service4} alt="cheeuforest_service4" />
              </div>
              <div className="cf_service_list_text">
                <h1 className="cf_service_list_title">Innovation</h1>
                <h3 className="cf_service_list_subtitle">
                  In line with the changing times, VR and the mental health
                  field are combined to open a new possibility in the mental
                  health field and establish a new paradigm for psychological
                  counseling.
                </h3>
              </div>
            </figure>
          </div>
        </div>
      </div>

      <div className="cf_program">
        <div class="inner" id="cf_program_02">
          <div className="title">
            <p className="cf_number">1</p>
            <div className="cf_text">
              <p className="cf_text01">Mental Health Education</p>
              <p className="cf_text02">Mind Learning</p>
            </div>
          </div>
          <div class="subtitle">
            <h1 className="cf_program_title">
              Education for Helping Mental Health
            </h1>
            <p className="cf_program_subtitle">
              Systematic education ranging from the causes and therapies of
              depression to stress management methods and mentalization
            </p>
          </div>
        </div>

        <div class="inner" id="cf_program_03">
          <div className="title">
            <p className="cf_number">2</p>
            <div className="cf_text">
              <p className="cf_text01">Emotional Regulation Training</p>
              <p className="cf_text02">Mind Training</p>
            </div>
          </div>
          <div className="subtitle">
            <h1 className="cf_program_title">
              Customized Training for Emotion and Impulse Control
            </h1>
            <p className="cf_program_subtitle">
              Organized based on the contents of time-tested Cognitive
              Behavioral Therapy, available for practice with case videos{" "}
            </p>
          </div>
          <div>
            <ControlledCarousel />
          </div>
        </div>

        <div className="cf_target">
          <div class="inner">
            <div className="title">Target</div>
            <div class="text">
              <p id="cf_service_subtitle">
                {" "}
                CHEEU. Forest can be utilized anywhere mental health care is
                needed, ranging from ordinary people to emotional workers,
                high-risk groups for stress and mental health-related
                institutions.
              </p>
            </div>
            <div class="cf_target_img">
              <img
                id="cheeuforest_target1"
                src={cheeuforest_target1}
                alt="cheeuforest_target1"
              />
              <p>
                <span>General people</span>
                <br />
                who are having difficulty in emotional control or interpersonal
                relations, etc. and curious about how to improve and maintain
                mental health
              </p>
              <img
                id="cheeuforest_target2"
                src={cheeuforest_target2}
                alt="cheeuforest_target2"
              />
              <p>
                <span>
                  Firefighters | Police Officers | CS Counselors | Other
                  Emotional Workers
                </span>{" "}
                <br />
                in Service Industry, High-Risk Groups for Stress, etc.
              </p>
              <img
                id="cheeuforest_target3"
                src={cheeuforest_target3}
                alt="cheeuforest_target3"
              />
              <p>
                <span>Mental health-related institutions</span> <br />
                such as department of psychiatry, psychological counseling
                centers, mental health promotion centers, local health centers,
                etc.
              </p>
              <img
                id="cheeuforest__kiosk"
                src={cheeuforest_kiosk}
                alt="cheeuforest_kiosk"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
