import "swiper/swiper-bundle.css"; // Swiper 스타일을 가져옵니다.
import "swiper/css/bundle";
import "swiper/css";

import "./RandD.css";
import TopButton from "../components_library/TopButton";
import ic_RandD from "@asset/icon/ic_R&D.png";
import Paper from "../components_library/Paper";

export default function RandD() {
  return (
    <div className="RandD">
      <TopButton />
      <div className="rd_main_bg">
        <div className="inner">
          <div className="rd_title">
            <img id="rd_logo" src={ic_RandD} alt="ic_RandD" />
            <p id="rd_title_01">연구개발</p>
            <h1 id="rd_title_02">R&D</h1>
          </div>
        </div>
      </div>

      <div className="rd_pipeline">
        <div className="inner">
          <div className="title">파이프라인</div>
          <div className="rd_pipeline_img">
            <div className="pipeline_img"></div>
          </div>
        </div>
      </div>

      <div className="rd_paper">
        <div className="inner">
          <div className="title">논문</div>
          <Paper />
        </div>
      </div>
    </div>
  );
}
