import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

import "./ControlledCarousel_youtube.css";

// src/ImageSlider.js

const ControlledCarousel_youtube = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 767);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const images = [
    {
      id: 1,
      src: "image1",
      link: "https://youtu.be/rEtvSkUvj2I",
      alt: "정신건강 원스톱 통합솔루션 제공 전문기업",
    },
    {
      id: 2,
      src: "image2",
      link: "https://youtu.be/VJVwnHyzGQ8",
      alt: "정신건강 VR 평가교육훈련 프로그램",
    },
    {
      id: 3,
      src: "image3",
      link: "https://youtu.be/yySS2ZIEyd0",
      alt: "K-BIC STAR DAY",
    },
    {
      id: 4,
      src: "image4",
      link: "https://youtu.be/r99sC1ur-M4",
      alt: "경계성 인격장애",
    },
    {
      id: 5,
      src: "image5",
      link: "https://youtu.be/ppPUEm99WOk",
      alt: "분노조절장애",
    },
    {
      id: 6,
      src: "image6",
      link: "https://youtu.be/Uqd4CQhCugI",
      alt: "코로나블루, 현명하게 극복하기",
    },
  ];

  return (
    <div className="controlledcarousel_youtube">
      {!isMobileView ? (
        <Carousel>
          {images.map((image) => (
            <Carousel.Item key={image.id}>
              <a href={image.link} target="_blank" rel="noopener noreferrer">
                <div className={`slider-image ${image.src}`}></div>
              </a>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <div className="vertical-image-list">
          {images.slice(0, 3).map((image) => (
            <a
              key={image.id}
              href={image.link}
              target="_blank"
              rel="noopener noreferrer"
              className="vertical-image-item"
            >
              <div className={`vertical-image ${image.src}`}></div>
            </a>
          ))}
          {showMore &&
            images.slice(3).map((image) => (
              <a
                key={image.id}
                href={image.link}
                target="_blank"
                rel="noopener noreferrer"
                className="vertical-image-item"
              >
                <div className={`vertical-image ${image.src}`}></div>
              </a>
            ))}
          {!showMore && (
            <button
              className="show-more-button"
              onClick={() => setShowMore(true)}
            >
              더보기
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ControlledCarousel_youtube;
