import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";

import "./ControlledCarousel.css";

import program3_1 from "@asset/image/img_cheeuforest_program3-1.png";
import program3_2 from "@asset/image/img_cheeuforest_program3-2.png";
import program3_3 from "@asset/image/img_cheeuforest_program3-3.png";
import program3_4 from "@asset/image/img_cheeuforest_program3-4.png";
import program3_5 from "@asset/image/img_cheeuforest_program3-5.png";
import program3_6 from "@asset/image/img_cheeuforest_program3-6.png";
import program3_7 from "@asset/image/img_cheeuforest_program3-7.png";

export default function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="ControlledCarousel">
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img className="d-block w-100" src={program3_1} alt="First slide" />
          <Carousel.Caption>
            {/* <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={program3_2} alt="First slide" />
          <Carousel.Caption>
            {/* <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={program3_3} alt="First slide" />
          <Carousel.Caption>
            {/* <h3>Third slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <img className="d-block w-100" src={program3_4} alt="First slide" />
          <Carousel.Caption>
            {/* <h3>fourth slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={program3_5} alt="First slide" />
          <Carousel.Caption>
            {/* <h3>fifth slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={program3_6} alt="First slide" />
          <Carousel.Caption>
            {/* <h3>sixth slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={program3_7} alt="First slide" />
          <Carousel.Caption>
            {/* <h3>seventh slide label</h3>
            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
