import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

import "./Header.css";
import logo from "@asset/logo/logo_MindsAI_color.png";
import { pageMapping, reversePageMapping } from "../../utils/pageMapping";

export default function Header({ language, setLanguage }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [prevLocation, setPrevLocation] = useState(useLocation().pathname);
  const [scrollToTop, setScrollToTop] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleNavLinkClick = (path) => {
    if (location.pathname === path) {
      setScrollToTop(true);
    } else {
      navigate(path);
      closeMenu();
    }
  };

  const handleLanguageChange = (lang) => {
    const currentPath = location.pathname;

    // 언어 상태 변경
    setLanguage(lang);

    // 새로운 경로가 없거나 메인 경로일 경우, 명확하게 설정
    if (currentPath === "/" || currentPath === "/en") {
      navigate(lang === "eng" ? "/en" : "/");
    } else {
      const newPath =
        lang === "eng"
          ? pageMapping[currentPath]
          : reversePageMapping[currentPath];
      navigate(newPath || (lang === "eng" ? "/en" : "/"));
    }
  };

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setScrollToTop(false);
    } else if (location.pathname !== prevLocation) {
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    }

    setPrevLocation(location.pathname);
  }, [location, prevLocation, scrollToTop]);

  return (
    <header>
      <div className="inner">
        {/* 로고 클릭 시 메인 페이지로 이동 */}
        <NavLink
          to={language === "kor" ? "/" : "/en"}
          onClick={() => {
            handleNavLinkClick(language === "kor" ? "/" : "/en");
            closeMenu();
          }}
        >
          <img className="logo" id="logo1" src={logo} alt="MindsAi_logo" />
        </NavLink>

        <nav className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
          <div className="logo">
            {/* 메뉴 안의 로고도 동일하게 적용 */}
            <NavLink
              to={language === "kor" ? "/" : "/en"}
              className={({ isActive }) =>
                location.pathname === (language === "kor" ? "/" : "/en")
                  ? "active"
                  : ""
              }
            >
              <img className="logo" src={logo} id="logo2" alt="MindsAi_logo" />
            </NavLink>
          </div>

          <div className="close-icon" onClick={toggleMenu}>
            <FaTimes />
          </div>

          <ul className="menu">
            <li className="item" id="Home">
              <NavLink
                to={language === "kor" ? "/" : "/en"}
                className={({ isActive }) =>
                  (location.pathname === "/" && language === "kor") ||
                  (location.pathname === "/en/" && language === "eng")
                    ? "active"
                    : ""
                }
                onClick={() =>
                  handleNavLinkClick(language === "kor" ? "/" : "/en")
                }
                id="home"
              >
                {language === "kor" ? "회사소개" : "Introduction"}
              </NavLink>
            </li>
            <li className="item" id="MindsNavi">
              <NavLink
                to={language === "kor" ? "/mindsnavi" : "/en/mindsnavi"}
                className={({ isActive }) =>
                  location.pathname ===
                  (language === "kor" ? "/mindsnavi" : "/en/mindsnavi")
                    ? "active"
                    : ""
                }
                onClick={() =>
                  handleNavLinkClick(
                    language === "kor" ? "/mindsnavi" : "/en/mindsnavi",
                  )
                }
              >
                {language === "kor" ? "마인즈 내비" : "Minds.NAVI"}
              </NavLink>
            </li>
            <li className="item" id="CheeuForest">
              <NavLink
                to={language === "kor" ? "/cheeuforest" : "/en/cheeuforest"}
                className={({ isActive }) =>
                  location.pathname ===
                  (language === "kor" ? "/cheeuforest" : "/en/cheeuforest")
                    ? "active"
                    : ""
                }
                onClick={() =>
                  handleNavLinkClick(
                    language === "kor" ? "/cheeuforest" : "/en/cheeuforest",
                  )
                }
              >
                {language === "kor" ? "치유 포레스트" : "CHEEU.Forest"}
              </NavLink>
            </li>
            <li className="item" id="CheeuCounsel">
              <NavLink
                to={language === "kor" ? "/cheeucounsel" : "/en/cheeucounsel"}
                className={({ isActive }) =>
                  location.pathname ===
                  (language === "kor" ? "/cheeucounsel" : "/en/cheeucounsel")
                    ? "active"
                    : ""
                }
                onClick={() =>
                  handleNavLinkClick(
                    language === "kor" ? "/cheeucounsel" : "/en/cheeucounsel",
                  )
                }
              >
                {language === "kor"
                  ? "치유 심리상담"
                  : "CHEEU Psychological Counseling"}
              </NavLink>
            </li>
            <li className="item" id="RandD">
              <NavLink
                to={language === "kor" ? "/randd" : "/en/randd"}
                className={({ isActive }) =>
                  location.pathname ===
                  (language === "kor" ? "/randd" : "/en/randd")
                    ? "active"
                    : ""
                }
                onClick={() =>
                  handleNavLinkClick(
                    language === "kor" ? "/randd" : "/en/randd",
                  )
                }
              >
                {language === "kor" ? "R&D" : "R&D"}
              </NavLink>
            </li>
            <li className="item" id="propose">
              <NavLink
                to={language === "kor" ? "/propose" : "/en/propose"}
                className={({ isActive }) =>
                  location.pathname ===
                  (language === "kor" ? "/propose" : "/en/propose")
                    ? "active"
                    : ""
                }
                onClick={() =>
                  handleNavLinkClick(
                    language === "kor" ? "/propose" : "/en/propose",
                  )
                }
              >
                {language === "kor" ? "제휴·제안 문의" : "Partnership·Proposal"}
              </NavLink>
            </li>
            {/* <li className="item" id="ces">
              <NavLink
                to={"/ces"}
                className={({ isActive }) =>
                  location.pathname.includes("/ces") ? "active" : ""
                }
                onClick={() => handleNavLinkClick("/ces")}
              >
                {"CES 2025"}
              </NavLink>
            </li> */}
          </ul>

          <ul className="language">
            <li
              to={language === "kor" ? "active" : "/"}
              id="kor"
              className={`language__name ${language === "kor" ? "active" : ""}`}
              onClick={() => handleLanguageChange("kor")}
            >
              KOR
            </li>
            <li className="middle">|</li>
            <li
              to={language === "eng" ? "active" : "/en"}
              id="eng"
              className={`language__name ${language === "eng" ? "active" : ""}`}
              onClick={() => handleLanguageChange("eng")}
            >
              ENG
            </li>
          </ul>
        </nav>

        <div className="menu-icon" onClick={toggleMenu}>
          <FaBars />
        </div>
      </div>
    </header>
  );
}
