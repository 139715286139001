import React, { useState } from "react";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css"; // Swiper 스타일을 가져옵니다.
import "swiper/css/bundle";
import "swiper/css";

import "./MindsNaviEng.css";

import TopButton from "../components_library/TopButton";

import ic_mindsnavi from "@asset/icon/ic_mindsnavi.png";
import logoset_w from "@asset/logo/logo_MindsAI_white.png";
import logoset from "@asset/logo/logo_MindsAI_color.png";

import service_process01 from "@asset/image/img_mindsnavi_service_01.png";
import service_process02 from "@asset/image/img_mindsnavi_service_02.png";
import service_process03 from "@asset/image/img_mindsnavi_service_03.png";
import service_process04 from "@asset/image/img_mindsnavi_service_04.png";
import service_process05 from "@asset/image/img_mindsnavi_service_05.png";
import service_arrow from "@asset/image/img_mindsnavi_service_arrow.png";

import program_prove_01 from "@asset/icon/ic_mindsnavi_prove_1.png";
import program_prove_02 from "@asset/icon/ic_mindsnavi_prove_2.png";
import program_prove_03 from "@asset/icon/ic_mindsnavi_prove_3.png";
import program_prove_04 from "@asset/icon/ic_mindsnavi_prove_4.png";
import program_prove_05 from "@asset/icon/ic_mindsnavi_prove_5.png";
import program_prove_06 from "@asset/icon/ic_mindsnavi_prove_6.png";

import program_mockup01 from "@asset/image/img_mindsnavi_mockup1.png";
import program_mockup02 from "@asset/image/img_mindsnavi_mockup2.png";
import program_mockup03 from "@asset/image/img_mindsnavi_mockup3.png";

export default function MindsNaviEng() {
  return (
    <div className="mindsnaviEng">
      <TopButton />
      <div className="mm_main_bg">
        <div className="inner">
          <div className="mm_title">
            <img id="mm_logo" src={ic_mindsnavi} alt="ic_mindsnavi" />
            <h1 id="mm_title_02">
              Mental health analysis and assessment program
            </h1>
            <div className="mm_title_01">
              <p>
                that can check depressive symptoms and stress state
                <br />
                through PROVE Assessment and salivary hormone analysis
              </p>
            </div>
          </div>

          <div className="mm_subtitle">
            <h1 id="mm_subtitle_01">Minds. NAVI Program</h1>
            <p id="mm_subtitle_02">
              Check the depressive state and the vulnerable and protective
              factors of the mental health through psychological assessment
              <br />
              and objectively identify a stress index by analyzing the secretion
              rate of Cortisol and DHEA hormone <br />
              in saliva to provide solutions and comprehensive results for
              mental health
            </p>
            <div className="mm_sub_logo">
              <img id="mm_logoset_w" src={logoset_w} alt="logoset" />
              <img id="mm_logoset" src={logoset} alt="logoset" />
            </div>
          </div>
        </div>
      </div>

      <div className="mm_service">
        <div className="inner">
          <div className="title">Service Process</div>
          <div className="service_contents">
            <figure className="service_con">
              <img
                className="service_process"
                src={service_process01}
                alt="service_process01"
              />
              <p className="service_process_text">
                Receive the Minds. NAVI kit
              </p>
            </figure>

            <figure>
              <img
                className="service_arrow"
                src={service_arrow}
                alt="service_arrow"
              />
            </figure>

            <figure className="service_con">
              <img
                className="service_process"
                src={service_process02}
                alt="service_process02"
              />
              <p className="service_process_text">
                Complete the psychological <br />
                inventory (online)
              </p>
            </figure>

            <figure>
              <img
                className="service_arrow"
                src={service_arrow}
                alt="service_arrow"
              />
            </figure>

            <figure className="service_con">
              <img
                className="service_process"
                src={service_process03}
                alt="service_process03"
              />
              <p className="service_process_text">
                Collect saliva after mastering precautions
                <br />
                and instructions (a total of 4 times)
              </p>
            </figure>

            <figure>
              <img
                className="service_arrow"
                src={service_arrow}
                alt="service_arrow"
              />
            </figure>

            <figure className="service_con">
              <img
                className="service_process"
                src={service_process04}
                alt="service_process04"
              />
              <p className="service_process_text">
                Seal the kit and send it by C.O.D.
              </p>
            </figure>

            <figure>
              <img
                className="service_arrow"
                src={service_arrow}
                alt="service_arrow"
              />
            </figure>

            <figure className="service_con">
              <img
                className="service_process"
                src={service_process05}
                alt="service_process05"
              />
              <p className="service_process_text">
                Receive and check <br />
                the comprehensive result report
              </p>
            </figure>
          </div>
        </div>
      </div>

      <div className="mm_program_list">
        <div className="inner">
          <div className="title">Program Organization</div>
          <div className="mm_program_list_contents">
            <div className="mm_program_list_program" id="program_01">
              <ul className="mm_program_list_title">1</ul>
              <ul className="mm_program_list_subtitle">
                <li className="mm_program_list_subtitle_01">
                  Psychological Indicator Assessment
                </li>
                <li className="mm_program_list_subtitle_02">PROVE TEST</li>
              </ul>
            </div>
            <div className="mm_program_list_program" id="program_02">
              <ul className="mm_program_list_title">2</ul>
              <ul className="mm_program_list_subtitle">
                <li className="mm_program_list_subtitle_01">
                  Analysis of Physical Indicators by
                </li>
                <li className="mm_program_list_subtitle_02">
                  Salivary Hormone Analysis{" "}
                </li>
              </ul>
            </div>
            <div className="mm_program_list_program" id="program_03">
              <ul className="mm_program_list_title">3</ul>
              <ul className="mm_program_list_subtitle">
                <li className="mm_program_list_subtitle_01">Provision of</li>
                <li className="mm_program_list_subtitle_02">
                  {" "}
                  Comprehensive Result Report
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* mm_program01 */}
      <div className="mm_program" id="mm_program_01">
        <div className="inner">
          <div className="title">
            <div className="title--number">1</div>
            <div className="title--text">
              <p className="title--text--first">PROVE TEST</p>
              <p className="title--text--second">
                Psychological Indicator Assessment
              </p>
            </div>
          </div>
          <div className="contents">
            <p className="contents__01">
              Self-report Questionnaire PROVE Test Program
            </p>
            <p className="contents__02">
              (Depressive symptomatology & PROtective-VulnErable factor Battery
              Test)
            </p>
            <p className="contents__03">
              Jointed developted by specialists, clinical psychologists,
              counseling psychologists & MINDSAI R&D Center
            </p>
            <img
              className="program-mockup"
              id="program01--mockup"
              src={program_mockup01}
              alt=""
            />
            <p className="contents__04">
              The assessment results are automatically calculated to ensure that
              an individual can recognize vulnerable and protective factors in
              terms of mental health and continue to manage his/her mental
              health even if he/she currently does not perceive depressive
              symptoms in a subjective way.{" "}
            </p>
            <div className="contents--emphasisbox">
              *PROVE test for Screening Depressive Symptoms and Mental Health
              Protective-Vulnerable Factors
            </div>

            <div className="prove">
              <div className="prove-title">
                <p className="contents__01">PROVE Assessment Details</p>
                <p className="contents__02">
                  Assessment of Depressive State and Protective/Vulnerable
                  Factors for Mental Health
                </p>
              </div>
              <div className="prove-contents">
                <div id="prove_1">
                  <img src={program_prove_01} />
                  <ul className="prove-contents_text">
                    <li className="prove_title">Depressive Symptoms</li>
                    <li className="prove_subtitle">
                      Sleep state, mood, weight changes, depression, daily
                      activities, etc.
                    </li>
                  </ul>
                </div>
                <div id="prove_2">
                  <img src={program_prove_02} />
                  <ul className="prove-contents_text">
                    <li className="prove_title">Adult Attachment Type</li>
                    <li className="prove_subtitle">
                      Attachment pattern type in an intimate relationship,
                      whether you can maintain a stable relationship
                    </li>
                  </ul>
                </div>
                <div di="prove_3">
                  <img src={program_prove_03} />
                  <ul className="prove-contents_text">
                    <li className="prove_title">
                      Adverse Childhood Experience
                    </li>
                    <li className="prove_subtitle">
                      Whether you have been exposed to severe psychological
                      trauma such as abuse, negligence, etc. during the growth
                      period
                    </li>
                  </ul>
                </div>
                <div id="prove_4">
                  <img src={program_prove_04} />
                  <ul className="prove-contents_text">
                    <li className="prove_title">Suicide Risk</li>
                    <li className="prove_subtitle">Suicide risk and level</li>
                  </ul>
                </div>
                <div id="prove_5">
                  <img src={program_prove_05} />
                  <ul className="prove-contents_text">
                    <li className="prove_title">Mentalization Capacity</li>
                    <li className="prove_subtitle">
                      Ability to understand my and others' mind
                    </li>
                  </ul>
                </div>
                <div id="prove_6">
                  <img src={program_prove_06} />
                  <ul className="prove-contents_text">
                    <li className="prove_title">Resilience</li>
                    <li className="prove_subtitle">
                      Power of mind to overcome difficult situations
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mm_program" id="mm_program_02">
        <div className="inner">
          <div className="title">
            <div className="title--number">2</div>
            <div className="title--text">
              <p className="title--text--first" id="title--text--firstfirst">
                Salivary Hormone Analysis
              </p>
              <p className="title--text--second">
                Analysis of Physical Indicators
              </p>
            </div>
          </div>
          <div className="contents">
            <p className="contents__01">Saliva Collection (Cortisol, DHEA)</p>
            <p className="contents__03">
              A program that analyzes the hormone secretion rate according to
              stress to identify the current stress level, the duration, etc.{" "}
              <br />
              and assess a stress response ability in a more objective and
              scientific manner
            </p>
            <img
              className="program-mockup"
              id="program02--mockup"
              src={program_mockup02}
              alt=""
            />
            <ul className="contents__04">
              <li>
                {" "}
                Derive an exact result for physical indicators only with saliva
                that is collected easily and conveniently anytime, anywhere by
                using a kit including a tube/a funnel for saliva collection{" "}
              </li>
              <li>
                {" "}
                Minds. NAVI uses the ELISA method which is much higher in
                precision and measurement sensitivity when analyzing saliva.{" "}
              </li>
              <li>
                {" "}
                Comprehensively assess the adrenocortical function state in 9
                stages by predicting a stress level and analyzing a ratio
                between two hormones, Cortisol and DHEA, with an automatic
                analysis program utilizing the patented technology{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="mm_program" id="mm_program_03">
        <div className="inner">
          <div className="title">
            <div className="title--number">3</div>
            <div className="title--text">
              <p className="title--text--first">Provision</p>
              <p className="title--text--second">
                of Comprehensive Result Report
              </p>
            </div>
          </div>
          <div className="contents">
            <ul className="contents__03">
              <li>
                An analysis report that a psychological assessment result and a
                salivary hormone analysis result are integrated{" "}
              </li>
              <li>
                The current stress level and mental health state are expressed
                in stages by combining and analyzing biochemical indicators and
                psychosocial indicators.
              </li>
              <li>
                The assessment results are provided to confirm and check mental
                health, prevent depression and prevent depression from getting
                worse.{" "}
              </li>
              <li>
                Conducted under the guidance and supervision of Jeongho Seok,
                CEO of MINDSAI Co., Ltd. (a specialist in psychiatry at Gangnam
                Severance Hospital)
              </li>
              <li>
                {" "}
                The MINDS. NAVI assessment result is provided to the subject
                through the review of experts who acquired an accredited
                certificate such as a clinical psychologist (issued by Korean
                Clinical Psychology Association)/ <br />
                mental health clinical psychologist Class 1 (issued by Ministry
                of Health & Welfare)/ counseling psychologist Class 1 (issued by
                Korean Counseling Psychological Association).
              </li>
            </ul>
            <div className="contents--emphasisbox">
              *Registered the copyright for 「Minds. NAVI Result Form」
            </div>
            <img
              className="program-mockup"
              id="program03--mockup"
              src={program_mockup03}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
