import React, { useState } from "react";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css"; // Swiper 스타일을 가져옵니다.
import "swiper/css/bundle";
import "swiper/css";

import "./HomeEng.css";

import AccordionEng from "../components_library/MyAccordionEng.js";
import TopButton from "../components_library/TopButton.js";
import ControlledCarousel_youtubeEng from "../components_library/ControlledCarousel_youtubeEng.js";
// import ControlledCarousel_Tab from './components_library/ControlledCarousel_Tab';

import NewsEng from "../components_library/NewsEng.js";

import ic_main from "@asset/icon/ic_main_video.png";
import logo_eng from "@asset/logo/logo_eng.png";

import ourmission from "@asset/image/img_ourmission.png";

// partners image
import img_partners01 from "@asset/partners/img_partners01.png";
import img_partners02 from "@asset/partners/img_partners02.png";
import img_partners03 from "@asset/partners/img_partners03.png";
import img_partners04 from "@asset/partners/img_partners04.png";
import img_partners05 from "@asset/partners/img_partners05.png";

import img_partners06 from "@asset/partners/img_partners06.png";
import img_partners07 from "@asset/partners/img_partners07.png";
import img_partners08 from "@asset/partners/img_partners08.png";
import img_partners09 from "@asset/partners/img_partners09.png";
import img_partners10 from "@asset/partners/img_partners10.png";

import img_partners11 from "@asset/partners/img_partners11.png";
import img_partners12 from "@asset/partners/img_partners12.png";
import img_partners13 from "@asset/partners/img_partners13.png";
import img_partners14 from "@asset/partners/img_partners14.png";
import img_partners15 from "@asset/partners/img_partners15.png";

import img_partners16 from "@asset/partners/img_partners16.png";
import img_partners17 from "@asset/partners/img_partners17.png";
import img_partners18 from "@asset/partners/img_partners18.png";
import img_partners19 from "@asset/partners/img_partners19.png";
import img_partners20 from "@asset/partners/img_partners20.png";

import img_partners21 from "@asset/partners/img_partners21.png";
import img_partners22 from "@asset/partners/img_partners22.png";
import img_partners23 from "@asset/partners/img_partners23.png";
import img_partners24 from "@asset/partners/img_partners24.png";

// import CesPopup from "../ces/CesPopup.js";

export default function HomeEng() {
  const [category, setCategory] = useState("");
  // const [showPopup, setShowPopup] = useState(true);

  // const handleCategoryChange = (event) => {
  //   setCategory(event.target.value);
  // };

  // const handleClosePopup = () => {
  //   setShowPopup(false);
  // };

  return (
    <div>
      <TopButton />
      <div className="homeEng">
        {/* {showPopup && <CesPopup onClose={handleClosePopup} />} */}
        <div className="mainvideo">
          <div class="inner">
            <img id="mv_ic" src={ic_main} alt="ic_main" />
            <h1 id="mv_title">
              Combination of Biotechnology and Artificial Intelligence,
              <br />
              Mental Health Service Company
            </h1>
            <img id="mv_logo_eng" src={logo_eng} alt="logo_eng" />
          </div>
        </div>

        <div className="ourmission">
          <div className="inner">
            <h1 id="title-1">Our Mission</h1>
            <p id="title-2">
              MindsAI has made lots of efforts to lower entry barriers to
              professional mental health services by changing the public
              perception of mental illness <br />
              and carefully considering accessibility to preventive management.
            </p>
            <h3 id="subtitle-1">
              Company for Providing One-Stop Integrated Solutions for Mental
              Health, MindsAI
            </h3>
            <p id="subtitle-2">
              We will take responsibility for the mental health of modern people{" "}
              <br />
              and contribute to improving the healthy mind and the quality of
              life of mankind.
              <br />
              Committed to ESG and sustainable business practices.
            </p>
            <img id="img_ourmission" src={ourmission} alt="ourmission" />
            {/* <div id="btn_download_normal"></div> */}
          </div>
        </div>

        <div className="business">
          <div className="inner">
            <div className="title">Business</div>

            <div className="business_contents">
              <div
                className="business_contents_inner"
                id="business_contents_01"
              >
                <p className="business_contents_title">01</p>
                <p className="business_contents_subtitle">
                  Mental Health Analysis and Assessment Program, Minds. NAVI
                </p>
                <p className="business_contents_text">
                  If you utilize PROVE Test and a saliva hormone analysis kit,
                  you can identify your depressive symptoms and stress state
                  through scientific and precise integrated analysis
                  (psychological indicators+biomarkers) anytime and anywhere
                  without any pressure.
                </p>
              </div>
              <div
                className="business_contents_inner"
                id="business_contents_02"
              >
                <p className="business_contents_title">02</p>
                <p className="business_contents_subtitle">
                  Mental Health VR Assessment · Education · Training Program,
                  CHEEU. Forest
                </p>
                <p className="business_contents_text">
                  You can manage your mental health consistently through the VR
                  education and training program that develops basic mental
                  strength and helps depression and stress management and
                  emotional regulation.
                </p>
              </div>
              <div
                className="business_contents_inner"
                id="business_contents_03"
              >
                <p className="business_contents_title">03</p>
                <p className="business_contents_subtitle">
                  Customized Psychological Counseling, CHEEU. Counsel
                </p>
                <p className="business_contents_text">
                  Through professional counseling from certified psychologists,
                  you can solve more fundamental mental problems and heal your
                  body and mind to be completely healthy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="history">
          <div className="inner">
            <div className="title">History</div>
            <AccordionEng />
          </div>
        </div>

        <div className="partners">
          <div className="title">Partners</div>
          <div className="inner">
            <p className="partners_subtitle">[ Public ]</p>
            <div className="partners_logo" id="public_logo">
              <img src={img_partners01} /> <img src={img_partners02} />{" "}
              <img src={img_partners03} />
              <img src={img_partners04} /> <img src={img_partners05} />{" "}
              <img src={img_partners18} />
              <img src={img_partners19} /> <img src={img_partners20} />{" "}
              <img src={img_partners21} />
              <img src={img_partners22} />
            </div>

            <p className="partners_subtitle">[ Medical ]</p>
            <div className="partners_logo" id="medical_logo">
              <img src={img_partners06} /> <img src={img_partners07} />{" "}
              <img src={img_partners08} />
              <img src={img_partners09} /> <img src={img_partners10} />{" "}
              <img src={img_partners11} />
              <img src={img_partners12} /> <img src={img_partners13} />{" "}
              <img src={img_partners14} />
              <img src={img_partners15} /> <img src={img_partners16} />{" "}
              <img src={img_partners17} />
            </div>

            <p className="partners_subtitle">[ Company ]</p>
            <div className="partners_logo" id="company_logo">
              <img src={img_partners23} /> <img src={img_partners24} />
            </div>
          </div>
        </div>

        <div className="media">
          <div className="inner">
            <a href="https://www.youtube.com/@cheeu342">
              <button id="btn-more-video">View More</button>
            </a>
            <div className="title">Media</div>
            <ControlledCarousel_youtubeEng />
          </div>
        </div>

        <div className="news">
          <div className="title">News</div>
          <NewsEng />
        </div>
      </div>
    </div>
  );
}
