import React, { useState } from "react";
import { Container, Row, Col, Nav, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";

import "./News.css";

const NewsEng = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [visibleItems, setVisibleItems] = useState({
    2024: 3,
    2023: 3,
    2022: 3,
    2021: 3,
    2020: 3,
    all: 3,
  });

  const news2024 = [
    {
      title: "Korea Biomedical Review",
      subtitle:
        "MINDSAI aims to tackle depression with innovative saliva-based diagnosis",
      body: "2024-08-14",
      url: "https://www.koreabiomed.com/news/articleView.html?idxno=24853",
    },

    {
      title: "The Medical News",
      subtitle:
        "Korea Association of Health Promotion Medicheck, Gave a big response to ‘MEDI-CHECK Mental Health Checkup’ Service for Workers",
      body: "2024-07-11",
      url: "http://www.bosa.co.kr/news/articleView.html?idxno=2226860",
    },

    {
      title: "Science-Chosun",
      subtitle: "Depression, Diagnosed with 'Stress Hormone' in saliva",
      body: "2024-05-30",
      url: "https://biz.chosun.com/science-chosun/medicine-health/2024/05/30/ZTV23FENEFAV5M4PJADSHKOTOI/",
    },

    {
      title: "Edaily",
      subtitle:
        "MINDSAI, Attracted subsequent investment in Series A worth of 1 billion won",
      body: "2024-05-07",
      url: "https://www.edaily.co.kr/News/Read?newsId=02217286638886624&mediaCodeNo=257&OutLnkChk=Y",
    },

    {
      title: "The Joong Bu Il Bo",
      subtitle:
        "Korea Association of Health Promotion Medicheck, Started to provide 'MEDI-CHECK Mental Health Checkup' Service",
      body: "2024-03-26",
      url: "https://www.joongboo.com/news/articleView.html?idxno=363643564",
    },

    {
      title: "Business Report",
      subtitle:
        "[MINDSAI] Development of 'Minds.NAVI', which classifies depression with saliva",
      body: "2024-02-26",
      url: "http://www.businessreport.kr/news/articleView.html?idxno=42100",
    },
  ];

  const news2023 = [
    {
      title: "The Korea Economic Daily",
      subtitle:
        "Attract the investment of 6 billion won on average…What are 4 baby unicorns to which you should pay attention for the first half year?",
      body: "2023-07-26",
      url: "https://www.hankyung.com/economy/article/202307170072i",
    },

    {
      title: "Seoul Metropolitan City",
      subtitle:
        " '2023 Seoul Youth Mental Health Support, First Recruitment of 2,500 participants in the second half of the year ",
      body: "2023-07-24",
      url: "https://www.seoul.go.kr/news/news_report.do#view/393233",
    },

    {
      title: "Maeil Business Newspaper",
      subtitle: "51 companies were selected as a baby unicorn",
      body: "2023-05-30",
      url: "https://www.mk.co.kr/news/it/10748617",
    },

    {
      title: "The Stock",
      subtitle:
        "The digital therapeutics market growing at the annual average of at least 20%... K-DTx Startups take aim for the market",
      body: "2023-03-16",
      url: "https://www.the-stock.kr/news/articleView.html?idxno=18016",
    },

    {
      title: "AJUNEWS",
      subtitle:
        '"The digital therapeutics market is expected to grow by an annual average of 27% in the country"... Digital Therapeutics gain increasing attention',
      body: "2023-01-18",
      url: "https://www.ajunews.com/view/?id=NISX20230117150028570",
    },

    {
      title: "The Chosun Ilbo",
      subtitle:
        "Could this year be recorded as the first year of digital therapeutics?...The commercialization of at least 2 digital therapeutics are imminent.",
      body: "2023-01-02",
      url: "https://it.chosun.com/news/articleView.html?idxno=2022123001042",
    },
  ];

  const news2022 = [
    {
      title: "MEDIGATE NEWS",
      subtitle:
        "7 companies are conducting confirmatory clinical trials for the approval of digital therapeutics",
      body: "2022-12-25",
      url: "https://www.medigatenews.com/news/1980836482",
    },

    {
      title: "DOCTORS NEWS",
      subtitle: "High-risk Depression, Predicted with Saliva",
      body: "2022-07-07",
      url: "https://www.doctorsnews.co.kr/news/articleView.html?idxno=145283",
    },

    {
      title: "ENEWS TODAY",
      subtitle:
        "'Digital Therapeutics' receiving increasing attention, Could they gain momentum for commercialization?",
      body: "2022-05-21",
      url: "https://www.enewstoday.co.kr/news/articleView.html?idxno=1571324",
    },

    {
      title: "The Korea Economic Daily",
      subtitle: "AI helps cure 'Illness of Mind'",
      body: "2022-05-17",
      url: "https://www.hankyung.com/it/article/2023051720391",
    },

    {
      title: "The Asia Business Daily ",
      subtitle:
        '"We have used salive to improve the accuracy of depression diagnosis"',
      body: "2022-02-03",
      url: "https://www.asiae.co.kr/article/2022020220151642889",
    },

    {
      title: "The Asia Business Daily ",
      subtitle:
        "MINDSAI, Obtained the approval of clinical trials for 2 types of mental health SW from Ministry of Food and Drug Safety",
      body: "2022-01-06",
      url: "https://view.asiae.co.kr/article/2022010614304799807",
    },
  ];

  const news2021 = [
    {
      title: "Medical Newspaper",
      subtitle:
        'MINDSAI was selected as "National Representative Innovative Company"',
      body: "2021-12-16",
      url: "http://www.bosa.co.kr/news/articleView.html?idxno=2164560",
    },

    {
      title: "MEDIGATE Youth Doctors",
      subtitle:
        '"The threshold for Department of Psychiatry should be lowered with Digital Technology"',
      body: "2021-12-06",
      url: "https://www.docdocdoc.co.kr/news/articleView.html?idxno=2017229",
    },

    {
      title: "MEDIGATE NEWS",
      subtitle:
        "Lowering treatment barriers to mental illness to Preventing dementia... Active research and development for digital therapeutics in the country",
      body: "2021-08-21",
      url: "https://www.medigatenews.com/news/2257353082",
    },
  ];

  const news2020 = [
    {
      title: "KUKINEWS",
      subtitle: "Cooperate to manage COVID-19 Burnout Syndrome",
      body: "2020-11-10",
      url: "https://www.kukinews.com/newsView/kuk202011100388",
    },

    {
      title: "SHINA Ilbo",
      subtitle:
        "Ministry of Health & Welfare, Take actions to manage COVID-19 Burnout Syndrome",
      body: "2020-11-10",
      url: "https://www.shinailbo.co.kr/news/articleView.html?idxno=1341240",
    },

    {
      title: "Mind Post",
      subtitle:
        "National Center for Mental Health, Signed the business agreement with MINDSAI",
      body: "2020-11-10",
      url: "http://www.mindpost.or.kr/bbs/board.php?bo_table=news&wr_id=3711&sfl=wr_subject%7C%7Cwr_content&stx=%EB%A7%88%EC%9D%B8%EC%A6%88%EC%97%90%EC%9D%B4%EC%95%84%EC%9D%B4&sop=and&page=1",
    },

    ///////////
    {
      title: "Doctor W",
      subtitle:
        "National Center for Mental Health and MINDSAI Co., Ltd signed the business agreement",
      body: "2020-11-09",
      url: "https://www.doctorw.co.kr/news/articleView.html?idxno=88493",
    },

    {
      title: "Yonhap News Agency",
      subtitle:
        "Manage the mental health of medical personnel who are suffering from COVID depression·'burnout', etc.",
      body: "2020-11-09",
      url: "https://www.yna.co.kr/view/AKR20201109102600530",
    },

    {
      title: "MEDIPANA News",
      subtitle:
        "National Center for Mental Health, Signed the business agreement with MINDSAI",
      body: "2020-11-09",
      url: "https://www.medipana.com/article/view.php?news_idx=265605",
    },

    {
      title: "The Dong-A Ilbo",
      subtitle:
        "Philip Morris Korea, Support psychological therapy for firefighters… Provide the VR mental health program",
      body: "2020-10-27",
      url: "https://www.donga.com/news/article/all/20201027/103661428/1",
    },

    {
      title: "Maeil Business Newspaper",
      subtitle:
        "Philip Morris Korea, Support psychological therapy for firefighters… Provide CHEEU. Forest",
      body: "2020-10-26",
      url: "https://www.mk.co.kr/news/business/9578316",
    },

    {
      title: "Newsis",
      subtitle:
        "Philip Morris Korea, Donated psychological therapy equipment for Daejeon Fire Headquarters",
      body: "2020-10-26",
      url: "https://newsis.com/view/?id=NISX20201026_0001211153&cID=10807&pID=10800",
    },

    {
      title: "Financial News",
      subtitle:
        "Philip Morris Korea, Supported psychological therapy for Daejeon Fire Headquarters",
      body: "2020-10-26",
      url: "https://www.fnnews.com/news/202010261607536404",
    },

    {
      title: "Medical Today",
      subtitle:
        "Daejeon Fire Headquarters signed the agreement for supporting psychological therapy for firefighters with Philip Morris Korea",
      body: "2020-10-26",
      url: "https://www.mdtoday.co.kr/mdtoday/index.html?no=403798",
    },

    {
      title: "The Public",
      subtitle:
        "Psychological counseling is also in the VR era. Take care of your mental health at CHEEU Psychological Counseling Center",
      body: "2020-10-21",
      url: "https://www.thepublic.kr/news/articleView.html?idxno=33817",
    },

    {
      title: "TECHWORLD ONLINE NEWS",
      subtitle:
        "COVID Blues, Take care of your mental health with remote psychological counseling ",
      body: "2020-10-21",
      url: "https://www.epnc.co.kr/news/articleView.html?idxno=106631",
    },
  ];

  const allNews = [
    ...news2024,
    ...news2023,
    ...news2022,
    ...news2021,
    ...news2020,
  ];

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => ({
      ...prevVisibleItems,
      [activeTab]: prevVisibleItems[activeTab] + 3,
    }));
  };

  const renderCarousel = (newsArray) => {
    return (
      <Carousel
        key={activeTab} // 탭 변경 시 Carousel이 리셋되도록 key를 activeTab으로 설정
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
          },
          desktop: {
            breakpoint: { max: 3000, min: 1480 },
            items: 3,
          },
          tablet: {
            breakpoint: { max: 1480, min: 992 },
            items: 2,
          },
          mobile: {
            breakpoint: { max: 992, min: 0 },
            items: 1,
            slidesToSlide: 1,
          },
        }}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {newsArray.map((news, index) => (
          <div
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(news.url)}
          >
            <h2 className="news-title">{news.title}</h2>
            <h1 className="news-subtitle">{news.subtitle}</h1>
            <p className="news-body">{news.body}</p>
          </div>
        ))}
      </Carousel>
    );
  };

  const renderList = (newsArray) => {
    return (
      <>
        <Row>
          {newsArray.slice(0, visibleItems[activeTab]).map((news, index) => (
            <Col key={index} xs={12} className="mb-3">
              <div
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(news.url)}
              >
                <h2 className="news-title">{news.title}</h2>
                <h1 className="news-subtitle">{news.subtitle}</h1>
                <p className="news-body">{news.body}</p>
              </div>
            </Col>
          ))}
        </Row>
        {visibleItems[activeTab] < newsArray.length && (
          <Button onClick={showMoreItems} className="mb-3">
            More
          </Button>
        )}
      </>
    );
  };

  const getNewsArray = () => {
    switch (activeTab) {
      case "2024":
        return news2024;
      case "2023":
        return news2023;
      case "2022":
        return news2022;
      case "2021":
        return news2021;
      case "2020":
        return news2020;
      case "all":
        return allNews;
      default:
        return [];
    }
  };

  const newsArray = getNewsArray();

  return (
    <Container className="news_container">
      <Nav variant="tabs" activeKey={activeTab} onSelect={setActiveTab}>
        <Nav.Item>
          <Nav.Link eventKey="all">All</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2024">2024</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2023">2023</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2022">2022</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2021">2021</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2020">2020</Nav.Link>
        </Nav.Item>
      </Nav>

      <Row>
        <Col>
          <div className="carousel-view">{renderCarousel(newsArray)}</div>
          <div className="list-view">{renderList(newsArray)}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default NewsEng;
