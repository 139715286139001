import React from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, Button, Row, Col, InputGroup } from "react-bootstrap";

import "./MyValidation.css";

const schema = yup.object().shape({
  category: yup.string().required("Required"),
  name: yup.string().required("Required"),
  phone: yup
    .string()
    .matches(
      /^\d{3}-\d{3,4}-\d{4}$/,
      "This is not a valid phone number format.",
    )
    .required("Required"),
  email: yup
    .string()
    .email("Please enter the correct email")
    .required("Required"),
  message: yup.string().required("Please enter your enquiry"),
  preference: yup
    .array()
    .of(yup.string())
    .min(1, "Please at least one business")
    .required("필수 항목입니다."),
  terms: yup
    .bool()
    .oneOf([true], "Agree on the Terms and Conditions of Use (Required)")
    .required("Agree on the Terms and Conditions of Use (Required)"),
});

function MyValidation() {
  const handleSubmit = async (values, { resetForm }) => {
    const emailData = {
      to: "mindsai@mindsai.co.kr",
      subject: `[제휴·제안문의]\n ${values.preference.join(", ")}\n | ${values.Name}\n(${values.category})`,
      text: `구분: ${values.category}\n이름: ${values.Name}\n연락처: ${values.call}\n이메일: ${values.email}\n문의사업: ${values.preference.join(", ")}\n문의내용: ${values.state}`,
      html: `
            <h1 style="font-size: 24px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:20px">제휴제안 문의</h1>
            <p style="font-size: 16px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:10px">구분 | ${values.category}</p>
            <p style="font-size: 16px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:10px">이름 | ${values.Name}</p>
            <p style="font-size: 16px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:10px">연락처 | ${values.call}</p>
            <p style="font-size: 16px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:10px">이메일 | ${values.email}</p>
            <p style="font-size: 16px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:10px">문의사업 | ${values.preference.join(", ")}</p>
            <p style="font-size: 16px; font-weight: bold; color: #162B4F; font-family: Pretendard-Regular; margin-bottom:10px">문의내용 | <br/>${values.state}</p>
          `,
    };

    try {
      const response = await fetch(
        "https://dev-api.mindstogo.co.kr/mail/v1/send",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": "e7e8d8f8-7564-44aa-88a3-2f6db0344009",
          },
          body: JSON.stringify(emailData),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to send email.");
      }

      alert("Email sent successfully.");
      resetForm();
    } catch (error) {
      console.error("Email sending failed:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        category: "",
        name: "",
        phone: "",
        email: "",
        message: "",
        preference: [],
        terms: false,
      }}
      validateOnChange={false} // 입력 중에는 검증하지 않음 //
      validateOnBlur={false} // 블러 시에도 검증하지 않음 //
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="formCategory">
              <Form.Label>Division</Form.Label>
              <div className="custom-select-wrapper">
                <Form.Control
                  classname="selectbox custom-select"
                  as="select"
                  name="category"
                  value={values.category}
                  onChange={handleChange}
                  isInvalid={!!errors.category}
                >
                  <option value="" disabled hidden>
                    -- Select Division --
                  </option>
                  <option value="public" label="Public Institution" />
                  <option value="company" label="Company" />
                  <option value="individual" label="Individual" />
                  <option value="etc" label="Others" />
                </Form.Control>
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.category}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formName">
              <Form.Label>Name/(Company Name or Institution Name)</Form.Label>
              <Form.Control
                placeholder="Name/(Company Name or Institution Name)"
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="formPhone">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                placeholder="ex) 010-1234-5678"
                type="text"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="formEmail">
              <Form.Label>E-mail</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  placeholder="ex) mindsai@mindsai.com"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="formPreference">
              <Form.Label>
                Inquiry Target (Multiple Selections Available)
              </Form.Label>
              <div className="checkbox-group">
                <Form.Check
                  type="checkbox"
                  label="Minds. NAVI"
                  value="마인즈 내비"
                  onChange={(e) => {
                    const value = e.target.value;
                    const isChecked = e.target.checked;
                    const newPreferences = isChecked
                      ? [...values.preference, value]
                      : values.preference.filter((pref) => pref !== value);
                    setFieldValue("preference", newPreferences);
                  }}
                  checked={values.preference.includes("마인즈 내비")}
                  isInvalid={!!errors.preference}
                  feedback={errors.preference}
                  feedbackType="invalid"
                />
                <Form.Check
                  type="checkbox"
                  label="CHEEU. Forest"
                  value="치유 포레스트"
                  onChange={(e) => {
                    const value = e.target.value;
                    const isChecked = e.target.checked;
                    const newPreferences = isChecked
                      ? [...values.preference, value]
                      : values.preference.filter((pref) => pref !== value);
                    setFieldValue("preference", newPreferences);
                  }}
                  checked={values.preference.includes("치유 포레스트")}
                  isInvalid={!!errors.preference}
                  feedback={errors.preference}
                  feedbackType="invalid"
                />
                <Form.Check
                  type="checkbox"
                  label="CHEEU. Counsel"
                  value="치유 심리상담"
                  onChange={(e) => {
                    const value = e.target.value;
                    const isChecked = e.target.checked;
                    const newPreferences = isChecked
                      ? [...values.preference, value]
                      : values.preference.filter((pref) => pref !== value);
                    setFieldValue("preference", newPreferences);
                  }}
                  checked={values.preference.includes("치유 심리상담")}
                  isInvalid={!!errors.preference}
                  feedback={errors.preference}
                  feedbackType="invalid"
                />
              </div>
              <Form.Control.Feedback type="invalid">
                {errors.preference}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="formMessage">
              <Form.Label>Inquiry</Form.Label>
              <Form.Control
                placeholder="Please enter your enquiry"
                as="textarea"
                rows={3}
                name="message"
                value={values.message}
                onChange={handleChange}
                isInvalid={!!errors.message}
              />
              <Form.Control.Feedback type="invalid">
                {errors.message}
              </Form.Control.Feedback>
              <p>
                {" "}
                MINDSAI Co., Ltd collects only the minimum personal information
                (name, mobile phone number, e-mail) to answer your inquiry.{" "}
                <br />
                The collected personal information will not be used for any
                other purpose and use.
              </p>
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="formTerms">
            <Form.Check
              required
              name="terms"
              label="I agree to collect my personal information."
              onChange={handleChange}
              isInvalid={!!errors.terms}
              feedback={errors.terms}
              feedbackType="invalid"
            />
          </Form.Group>

          <Button type="submit">Send a partnership proposal</Button>
        </Form>
      )}
    </Formik>
  );
}

export default MyValidation;
