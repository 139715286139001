import React, { useState } from "react";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css"; // Swiper 스타일을 가져옵니다.
import "swiper/css/bundle";
import "swiper/css";

import "./CheeuForest.css";

import TopButton from "../components_library/TopButton";

import ic_cheeuforest from "@asset/icon/ic_cheeuforest.png";
import logoset_w from "@asset/image/img_logoset_white.png";
import logoset from "@asset/image/img_logoset_colored.png";

import cheeuforest_service1 from "@asset/image/img_cheeuforest_service1.png";
import cheeuforest_service2 from "@asset/image/img_cheeuforest_service2.png";
import cheeuforest_service3 from "@asset/image/img_cheeuforest_service3.png";
import cheeuforest_service4 from "@asset/image/img_cheeuforest_service4.png";

import cheeuforest_target1 from "@asset/image/img_cheeuforest_target1.png";
import cheeuforest_target2 from "@asset/image/img_cheeuforest_target2.png";
import cheeuforest_target3 from "@asset/image/img_cheeuforest_target3.png";

import cheeuforest_kiosk from "@asset/image/img_cheeuforest_kiosk.png";

import ControlledCarousel from "../components_library/ControlledCarousel";

export default function CheeuForest() {
  return (
    <div className="cheeuforest">
      <TopButton />
      <div className="cf_main_bg">
        <div className="inner">
          <div className="cf_title">
            <img id="cf_logo" src={ic_cheeuforest} alt="ic_cheeuforest" />
            <div id="cf_title_01">
              <p id="cf_title_01-1">
                현대인의 마음의 병인 우울증과 스트레스 관리, 정서조절 훈련을
                위한
              </p>
            </div>
            <h1 id="cf_title_02">정신건강 VR평가·교육·훈련 프로그램</h1>
          </div>

          <div className="cf_subtitle">
            <h1 id="cf_subtitle_01">치유 포레스트 프로그램</h1>
            <p id="cf_subtitle_02">
              {" "}
              국가 R&D 과제를 통한 개발 「산업통상자원부 지식서비스산업핵심기술
              개발사업」 선정
            </p>
            <p id="cf_subtitle_03">
              {" "}
              전문의료진, 심리상담전문가, (주)마인즈에이아이 R&D센터가 함께 연구
              개발한 치유 포레스트는 <br />
              임상시험까지 완료하여 전문성과 신뢰성을 확보한 프로그램입니다.{" "}
            </p>
            <p id="cf_subtitle_04">
              {" "}
              *「치유 포레스트(CHEEU. Forest)」 저작권 등록
            </p>
            <div className="cf_sub_logo">
              <img id="cf_logoset_w" src={logoset_w} alt="logoset" />
              <img id="cf_logoset" src={logoset} alt="logoset" />
            </div>
          </div>
        </div>
      </div>

      <div className="cf_service">
        <div className="inner">
          <div className="title">서비스</div>
          <div className="text">
            <h1 id="cf_service_title">CHEEU. Forest(치유 포레스트)</h1>
            <p id="cf_service_subtitle">
              {" "}
              치유 포레스트는 체계적이고 차별화된 정신건강 관리로 여러분의 삶의
              질을 향상시켜드립니다.
            </p>
          </div>
          <div className="cf_service_contents">
            <figure className="cf_service_list" id="cf_service_list_01">
              <div className="cheeuforest_service_img">
                <img src={cheeuforest_service1} alt="cheeuforest_service1" />
              </div>
              <div className="cf_service_list_text">
                <h1 className="cf_service_list_title">신뢰성</h1>
                <h3 className="cf_service_list_subtitle">
                  강남세브란스병원 정신건강의학과 전문 의료진의 감수를 거쳐
                  제공되는 객관적이고 표준화된 평가, 교육, 훈련 서비스로 신뢰할
                  수 있는 서비스를 제공합니다.
                </h3>
              </div>
            </figure>

            <figure className="cf_service_list" id="cf_service_list_02">
              <div className="cheeuforest_service_img">
                <img src={cheeuforest_service2} alt="cheeuforest_service2" />
              </div>
              <div className="cf_service_list_text">
                <h1 className="cf_service_list_title">효과성</h1>
                <h3 className="cf_service_list_subtitle" id="special">
                  우울 증상이 있는 사람의 정서조절과 마음 헤아리기 능력 증진에
                  효과적이며, 정신 건강에 대한 이해도 향상과 건강 유지에도
                  도움이 됩니다.
                </h3>
                <p className="cf_service_list_body">
                  #임상시험 결과 우울점수와 자살위험성점수가 큰 폭으로 감소되어
                  프로그램 효과가 입증됨
                </p>
              </div>
            </figure>

            <figure className="cf_service_list" id="cf_service_list_03">
              <div className="cheeuforest_service_img">
                <img src={cheeuforest_service3} alt="cheeuforest_service3" />
              </div>
              <div className="cf_service_list_text">
                <h1 className="cf_service_list_title">편의성</h1>
                <h3 className="cf_service_list_subtitle">
                  VR 구동가능한 키오스크 형태로 접근성이 용이하고, 지속적인 자가
                  훈련을 진행할 수 있어 체계적인 정신건강 관리가 가능합니다.
                </h3>
              </div>
            </figure>

            <figure className="cf_service_list" id="cf_service_list_04">
              <div className="cheeuforest_service_img">
                <img src={cheeuforest_service4} alt="cheeuforest_service4" />
              </div>
              <div className="cf_service_list_text">
                <h1 className="cf_service_list_title">혁신성</h1>
                <h3 className="cf_service_list_subtitle">
                  변화하는 시대에 맞추어 VR과 정신건강 분야를 결합함으로써
                  정신건강분야의 새로운 가능성을 열고 심리상담의 새로운
                  패러다임을 구축해갑니다.
                </h3>
              </div>
            </figure>
          </div>
        </div>
      </div>

      <div className="cf_program">
        <div class="inner" id="cf_program_02">
          <div className="title">
            <p className="cf_number">1</p>
            <div className="cf_text">
              <p className="cf_text01">정신건강 교육</p>
              <p className="cf_text02">마음 배우기</p>
            </div>
          </div>
          <div class="subtitle">
            <h1 className="cf_program_title">정신건강에 대한 돕는 교육</h1>
            <p className="cf_program_subtitle">
              {" "}
              우울증의 원인과 치료부터 스트레스 관리방법, 마음 헤아리기까지
              체계적인 교육 진행
            </p>
          </div>
        </div>

        <div class="inner" id="cf_program_03">
          <div className="title">
            <p className="cf_number">2</p>
            <div className="cf_text">
              <p className="cf_text01">정서조절 훈련</p>
              <p className="cf_text02">마음 훈련하기</p>
            </div>
          </div>
          <div className="subtitle">
            <h1 className="cf_program_title">
              정서 및 충동 조절을 위한 맞춤형 훈련
            </h1>
            <p className="cf_program_subtitle">
              {" "}
              효과가 검증된 인지행동치료 내용을 바탕으로 구성, 사례 동영상을
              활용하여 연습 가능
            </p>
          </div>
          <div>
            <ControlledCarousel />
          </div>
        </div>

        <div className="cf_target">
          <div class="inner">
            <div className="title">대상</div>
            <div class="text">
              <p id="cf_service_subtitle">
                {" "}
                일반인에서부터 감정 노동자, 스트레스 고위험군, 정신건강 관련
                기관에 이르기까지 <br />
                정신건강 케어가 필요한 모든 곳에서 치유 포레스트는 활용
                가능합니다.
              </p>
            </div>
            <div class="cf_target_img">
              <img
                id="cheeuforest_target1"
                src={cheeuforest_target1}
                alt="cheeuforest_target1"
              />
              <p>
                감정조절이나 대인관계 등에서 어려움을 겪고 있어 <br />
                정신건강 증진 및 유지 방법이 궁금한 <span>일반인</span>
              </p>
              <img
                id="cheeuforest_target2"
                src={cheeuforest_target2}
                alt="cheeuforest_target2"
              />
              <p>
                <span>소방관 | 경찰관 | CS상담원 | 기타 서비스직</span> <br />
                감정 노동자, 스트레스 고위험군 등
              </p>
              <img
                id="cheeuforest_target3"
                src={cheeuforest_target3}
                alt="cheeuforest_target3"
              />
              <p>
                <span>정신건강 관련 기관</span> <br />
                정신건강의학과 및 심리상담센터,
                <br />
                정신건강증진센터, 지자체 보건소 등
              </p>
              <img
                id="cheeuforest__kiosk"
                src={cheeuforest_kiosk}
                alt="cheeuforest_kiosk"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
