import { createContext, useState, useContext, useEffect } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  deleteField,
} from "firebase/firestore";
import { db } from "../../../firebase/firebase";

export const ReservationContext = createContext({
  reservedData: {},
  setReservedData: () => {},
  selectedDate: null,
  setSelectedDate: () => {},
  selectedTime: null,
  setSelectedTime: () => {},
  selectedSlot: null,
  setSelectedSlot: () => {},
  isLoading: true,
});

export const ReservationProvider = ({ children }) => {
  const [reservedData, setReservedData] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [timeSlots, setTimeSlots] = useState({});
  const [reservedTimeString, setReservedTimeString] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const getReservationData = async () => {
      try {
        setIsLoading(true);
        // console.log("Fetching reservation data...");
        const reservation = await getDocs(collection(db, "reservation"));
        // console.log(
        //   "Raw Firestore docs:",
        //   reservation.docs.map((doc) => ({ id: doc.id, data: doc.data() })),
        // );

        const data = {};
        const slots = {};

        reservation.docs.forEach((doc, index) => {
          const docData = doc.data();
          const clientData = docData.client || {};

          // reservedData 용 데이터
          data[doc.id] = {
            ...docData,
            docId: doc.id,
            date: docData.date || "",
            time: docData.time || "",
            fullName: clientData.fullName || "",
            company: clientData.company || "",
            country: clientData.country || "",
            email: clientData.email || "",
            phone: clientData.phone || "",
            message: clientData.message || "",
            isReserved: docData.isReserved || false,
            createdAt: docData.createdAt || "",
          };

          // timeSlots 용 데이터
          slots[index] = {
            ...docData,
            docId: doc.id,
            date: docData.date || "",
            time: docData.time || "",
            isReserved: docData.isReserved || false,
            client: docData.client || null,
          };
        });

        // console.log("Processed data:", data);
        setReservedData(data);
        setTimeSlots(slots);
      } catch (error) {
        console.error("Error fetching reservation data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getReservationData();
  }, []);

  // 날짜별로 슬롯 그룹화
  const groupedSlots = Object.entries(timeSlots).reduce((acc, [key, slot]) => {
    if (!slot.date) return acc; // date가 없는 경우 건너뛰기

    const date = slot.date;
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push({ ...slot, index: key });
    return acc;
  }, {});

  // 각 날짜별로 시간순 정렬
  Object.values(groupedSlots).forEach((slots) => {
    slots.sort((a, b) => a.time.localeCompare(b.time));
  });

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
    setReservedTimeString(date);
  };

  const handleTimeSelect = (slotIndex) => {
    if (!timeSlots[slotIndex]) {
      console.error("Invalid time slot selected:", slotIndex);
      return;
    }

    // 시간 선택만 수행
    setSelectedDate(timeSlots[slotIndex].date);
    setSelectedTime(slotIndex);
    setReservedTimeString(
      timeSlots[slotIndex].date + " " + timeSlots[slotIndex].time,
    );
  };

  const sendEmail = async (values, selectedSlot) => {
    const emailData = {
      from: "mindsai@mindsai.co.kr",
      to: [
        "doyeonjeong@mindsai.co.kr",
        values.email,
        "johnstein@mindsai.co.kr",
        "ahyunia@mindsai.co.kr",
        "hkshin0716@mindsai.co.kr",
      ],
      subject: `[CES 예약문의] ${values.fullName} | ${selectedSlot.date} ${selectedSlot.time}`,
      text: `
        이름: ${values.fullName}
        회사: ${values.company || ""}
        국가: ${values.country || ""}
        이메일: ${values.email || ""}
        전화번호: ${values.phone || ""}
        예약일시: ${selectedSlot.date} ${selectedSlot.time}
        메시지: ${values.message || "없음"}
      `,
      html: `
        <div style="max-width: 600px; margin: 0 auto; padding: 30px; background-color: #ffffff; border: 1px solid #e0e0e0; border-radius: 8px; font-family: Pretendard-Regular, Arial, sans-serif;">
          <h1 style="font-size: 28px; font-weight: bold; color: #162B4F; text-align: center; margin-bottom: 30px; border-bottom: 2px solid #162B4F; padding-bottom: 15px;">CES 2025 Meeting Reservation</h1>
          
          <table style="width: 100%; border-collapse: collapse;">
            <tr>
              <td style="padding: 12px; background-color: #f8f9fa; color: #162B4F; font-weight: bold; width: 30%;">Name</td>
              <td style="padding: 12px; color: #333;">${values.fullName}</td>
            </tr>
            <tr>
              <td style="padding: 12px; background-color: #f8f9fa; color: #162B4F; font-weight: bold;">Company</td>
              <td style="padding: 12px; color: #333;">${values.company || "-"}</td>
            </tr>
            <tr>
              <td style="padding: 12px; background-color: #f8f9fa; color: #162B4F; font-weight: bold;">Country</td>
              <td style="padding: 12px; color: #333;">${values.country || "-"}</td>
            </tr>
            <tr>
              <td style="padding: 12px; background-color: #f8f9fa; color: #162B4F; font-weight: bold;">Email</td>
              <td style="padding: 12px; color: #333;">${values.email || "-"}</td>
            </tr>
            <tr>
              <td style="padding: 12px; background-color: #f8f9fa; color: #162B4F; font-weight: bold;">Phone</td>
              <td style="padding: 12px; color: #333;">${values.phone || "-"}</td>
            </tr>
            <tr>
              <td style="padding: 12px; background-color: #f8f9fa; color: #162B4F; font-weight: bold;">Reserved Time</td>
              <td style="padding: 12px; color: #333;">${selectedSlot.date} ${selectedSlot.time}</td>
            </tr>
          </table>

          <div style="margin-top: 25px; padding: 15px; background-color: #f8f9fa; border-radius: 4px;">
            <p style="font-weight: bold; color: #162B4F; margin-bottom: 10px;">Message</p>
            <p style="color: #333; line-height: 1.6;">${values.message || "No additional message"}</p>
          </div>

          <div style="margin-top: 30px; padding-top: 20px; border-top: 1px solid #e0e0e0; text-align: center; font-size: 12px; color: #666;">
            <p>This is an automated confirmation of your meeting reservation request.</p>
          </div>
        </div>
      `,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-API-Key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(emailData),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error("이메일 전송 응답:", errorText);
      throw new Error(`이메일 전송 실패! status: ${response.status}`);
    }

    return await response.json();
  };

  const handleFormSubmit = async (values) => {
    try {
      setIsSubmitting(true);

      // 선택된 시간 슬롯이 없으면 에러
      if (!selectedTime || !timeSlots[selectedTime]) {
        throw new Error("예약 시간을 선택해주세요.");
      }

      const selectedSlot = timeSlots[selectedTime];

      // 클라이언트 정보 준비
      const clientData = {
        ...values,
        name: values.fullName || "",
        company: values.company || "",
        country: values.country || "",
        email: values.email || "",
        phone: values.phone || "",
        message: values.message || "",
        reservationDate: reservedTimeString,
        createdAt: new Date().toISOString(),
      };

      // 이메일 전송 - 실패하면 예약도 중단
      await sendEmail(values, selectedSlot);

      // 선택된 시간 슬롯의 문서 업데이트
      await updateDoc(doc(db, "reservation", selectedSlot.docId), {
        isReserved: true,
        client: clientData,
      });

      // 로컬 상태 업데이트
      const updatedTimeSlots = { ...timeSlots };
      updatedTimeSlots[selectedTime] = {
        ...selectedSlot,
        isReserved: true,
        client: clientData,
      };
      setTimeSlots(updatedTimeSlots);

      return { success: true, message: "예약이 성공적으로 완료되었습니다." };
    } catch (error) {
      console.error("예약 제출 중 오류 발생:", error);
      return {
        success: false,
        message: error.message || "예약 중 오류가 발생했습니다.",
      };
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitReservation = async (values) => {
    if (!values || typeof values !== "object") {
      console.error("Form values are required for submission");
      return { success: false, message: "잘못된 폼 데이터입니다." };
    }

    return handleFormSubmit(values);
  };

  const handleCancelReservations = async (docIds) => {
    try {
      setIsLoading(true);
      for (const docId of docIds) {
        await updateDoc(doc(db, "reservation", docId), {
          isReserved: false,
          client: deleteField(),
        });
      }
      // 데이터 다시 불러오기
      const reservation = await getDocs(collection(db, "reservation"));
      const data = {};
      const updatedTimeSlots = { ...timeSlots };

      reservation.docs.forEach((doc) => {
        const docData = doc.data();
        const clientData = docData.client || {};
        const docInfo = {
          ...docData,
          docId: doc.id,
          date: docData.date || "",
          time: docData.time || "",
          fullName: clientData.fullName || "",
          company: clientData.company || "",
          country: clientData.country || "",
          email: clientData.email || "",
          phone: clientData.phone || "",
          message: clientData.message || "",
          isReserved: docData.isReserved || false,
          createdAt: docData.createdAt || "",
        };

        data[doc.id] = docInfo;

        // timeSlots 상태도 업데이트
        Object.keys(updatedTimeSlots).forEach((key) => {
          if (updatedTimeSlots[key].docId === doc.id) {
            updatedTimeSlots[key] = {
              ...updatedTimeSlots[key],
              isReserved: false,
              client: null,
            };
          }
        });
      });

      setReservedData(data);
      setTimeSlots(updatedTimeSlots);
    } catch (error) {
      console.error("Error canceling reservations:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    reservedData,
    setReservedData,
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    selectedSlot,
    setSelectedSlot,
    isLoading,
    timeSlots,
    groupedSlots,
    handleDateSelect,
    handleTimeSelect,
    submitReservation,
    handleCancelReservations,
    isSubmitting,
    reservedTimeString,
  };

  return (
    <ReservationContext.Provider value={value}>
      {children}
    </ReservationContext.Provider>
  );
};

export const useReservation = () => {
  const context = useContext(ReservationContext);
  if (!context) {
    throw new Error("useReservation must be used within a ReservationProvider");
  }
  return context;
};
