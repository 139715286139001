import React, { useState } from "react";
import { Container, Row, Col, Nav, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";

import "./Paper.css";

const Paper = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [visibleItems, setVisibleItems] = useState({
    2024: 3,
    2023: 3,
    2022: 3,
    2021: 3,
    2020: 3,
    all: 3,
  });

  const paper2024 = [
    {
      title: "CHEEU. Forest",
      subtitle:
        "VR-based Cognitive Behavior Therapy for Major Depressive Disorder: An Alternative to Pharmacotherapy for Reducing Suicidality",
      body: "Lee M, et al., 2024, Yonsei Medical Journal, in acceptance",
    },
  ];

  const paper2023 = [
    {
      title: "Minds. NAVI",
      subtitle:
        "Exploratory clinical trial of a depression diagnostic software that integrates stress biomarkers and composite psychometrics. ",
      body: "Jang S, et al., 2023, Psychiatry Investigation",
    },

    {
      title: "Minds. NAVI",
      subtitle:
        "수면 문제 선별 질문지의 신뢰도, 타당도 연구: 불면증상을 중심으로. ",
      body: "이주열 외., 2023, 수면정신생리",
    },

    {
      title: "바라봄-D",
      subtitle:
        "Virtual reality-based monitoring test for MCI: A multicenter feasibilty study.",
      body: "Jang S, et al., 2023, Frontiers in Psychiatry",
    },
  ];

  const paper2022 = [
    {
      title: "Minds. NAVI",
      subtitle:
        "Relationship of Resilience Factors With Biopsychosocial Markers Using a Comprehensive Home Evaluation Kit for Depression and Suicide Risk: A Real-World Data Analysis.",
      body: "Jang S, et al., 2022,  Frontiers in Psychiatry ",
    },

    {
      title: "바라봄-D",
      subtitle:
        "경도인지장애 선별 평가를 위한 가상현실 인지기능평가의 개발: 예비 연구. ",
      body: "강민재 외., 2022 신경정신의학",
    },
  ];

  const paper2021 = [
    {
      title: "Minds. NAVI",
      subtitle:
        "우울증상 및 정신건강 보호-취약요인 선별평가를 위한 배터리 평가도구의 개발. ",
      body: "이주열 외., 2021, 신경정신의학",
    },
  ];

  const paper2020 = [
    {
      title: "치유포레스트",
      subtitle:
        "가상현실기법을 활용한 정신건강교육 및 기술훈련 프로그램의 우울증상 회복 및 자살위험성 감소 효과.",
      body: "김민지 외., 2020. 신경정신의학",
    },
  ];

  const allPaper = [
    ...paper2024,
    ...paper2023,
    ...paper2022,
    ...paper2021,
    ...paper2020,
  ];

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  // const handleClick = (url) => {
  //   window.open(url, "_blank");
  // };

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => ({
      ...prevVisibleItems,
      [activeTab]: prevVisibleItems[activeTab] + 3,
    }));
  };

  const renderCarousel = (paperArray) => {
    return (
      <Carousel
        key={activeTab} // 탭 변경 시 Carousel이 리셋되도록 key를 activeTab으로 설정
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
          },
          desktop: {
            breakpoint: { max: 3000, min: 1480 },
            items: 3,
          },
          tablet: {
            breakpoint: { max: 1480, min: 992 },
            items: 2,
          },
          mobile: {
            breakpoint: { max: 992, min: 0 },
            items: 1,
            slidesToSlide: 1,
          },
        }}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {paperArray.map((paper, index) => (
          <div
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            // onClick={() => handleClick(paper.url)}
          >
            <h2 className="paper-title">{paper.title}</h2>
            <h1 className="paper-subtitle">{paper.subtitle}</h1>
            <p className="paper-body">{paper.body}</p>
          </div>
        ))}
      </Carousel>
    );
  };

  const renderList = (paperArray) => {
    return (
      <>
        <Row>
          {paperArray.slice(0, visibleItems[activeTab]).map((paper, index) => (
            <Col key={index} xs={12} className="mb-3">
              <div
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                // onClick={() => handleClick(paper.url)}
              >
                <h2 className="paper-title">{paper.title}</h2>
                <h1 className="paper-subtitle">{paper.subtitle}</h1>
                <p className="paper-body">{paper.body}</p>
              </div>
            </Col>
          ))}
        </Row>
        {visibleItems[activeTab] < paperArray.length && (
          <Button onClick={showMoreItems} className="mb-3">
            더보기
          </Button>
        )}
      </>
    );
  };

  const getpaperArray = () => {
    switch (activeTab) {
      case "2024":
        return paper2024;
      case "2023":
        return paper2023;
      case "2022":
        return paper2022; // 필요에 따라 추가
      case "2021":
        return paper2021; // 필요에 따라 추가
      case "2020":
        return paper2020; // 필요에 따라 추가
      case "all":
        return allPaper;
      default:
        return [];
    }
  };

  const paperArray = getpaperArray();

  return (
    <Container className="paper_container">
      <Nav variant="tabs" activeKey={activeTab} onSelect={setActiveTab}>
        <Nav.Item>
          <Nav.Link eventKey="all">All</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2024">2024</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2023">2023</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2022">2022</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2021">2021</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="2020">2020</Nav.Link>
        </Nav.Item>
      </Nav>

      <Row>
        <Col>
          <div className="carousel-view">{renderCarousel(paperArray)}</div>
          <div className="list-view">{renderList(paperArray)}</div>
        </Col>
      </Row>
    </Container>
  );
};

export default Paper;
