import "./App.css";
import "./index.css";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Header from "./component/views/Header";
import Home from "./component/views/Home";
import HomeEng from "./component/views/HomeEng";

import MindsNavi from "./component/views/MindsNavi";
import MindsNaviEng from "./component/views/MindsNaviEng";

import CheeuForest from "./component/views/CheeuForest";
import CheeuForestEng from "./component/views/CheeuForestEng";

import CheeuCounsel from "./component/views/CheeuCounsel";
import CheeuCounselEng from "./component/views/CheeuCounselEng";

import RandD from "./component/views/RandD";
import RandDEng from "./component/views/RandDEng";

import Propose from "./component/views/Propose";
import ProposeEng from "./component/views/ProposeEng";

import Footer from "./component/views/Footer";

import ScrollToTop from "./component/components_library/ScrollToTop"; // ScrollToTop 컴포넌트 import
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// CES 관련 페이지
// import CesReservation from "./component/ces/reservation/CesReservationForm";
// import CesReservationAdmin from "./component/ces/reservation/admin/ReservationAdmin";
// import AdminLogin from "./component/ces/reservation/admin/AdminLogin";
// import Landing from "./component/ces/landing";
import { ReservationProvider } from "./component/ces/context/ReservationContext";

function AppRouter({ language, setLanguage }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    const lang = userLanguage.startsWith("en") ? "eng" : "kor";
    setLanguage(lang);
  }, [setLanguage]);

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    const newPath = lang === "eng" ? "/en" : "/";
    navigate(newPath, { replace: true });
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path.startsWith("/en")) {
      setLanguage("eng");
    } else {
      setLanguage("kor");
    }
  }, [location.pathname, setLanguage]);

  useEffect(() => {
    if (location.pathname === "/en") {
      navigate("/en/", { replace: true });
    }
  }, [location.pathname, navigate]);

  return (
    <>
      <ScrollToTop />
      <Header language={language} setLanguage={handleLanguageChange} />
      <Routes>
        <Route path="/" element={language === "kor" ? <Home /> : <HomeEng />} />
        {/* 구체적인 경로를 먼저 매칭 */}
        {/* <Route path="/ces/reservation/admin/login" element={<AdminLogin />} />
        <Route
          path="/ces/reservation/admin"
          element={<CesReservationAdmin />}
        />
        <Route path="/ces/reservation" element={<CesReservation />} />
        <Route path="/ces" element={<Landing />} /> */}

        {/* 언어별 라우트 */}
        <Route path="/en/mindsnavi" element={<MindsNaviEng />} />
        <Route path="/en/cheeuforest" element={<CheeuForestEng />} />
        <Route path="/en/cheeucounsel" element={<CheeuCounselEng />} />
        <Route path="/en/randd" element={<RandDEng />} />
        <Route path="/en/propose" element={<ProposeEng />} />
        <Route path="/en" element={<HomeEng />} />

        {/* 기본 라우트 */}
        <Route
          path="/mindsnavi"
          element={language === "kor" ? <MindsNavi /> : <MindsNaviEng />}
        />
        <Route
          path="/cheeuforest"
          element={language === "kor" ? <CheeuForest /> : <CheeuForestEng />}
        />
        <Route
          path="/cheeucounsel"
          element={language === "kor" ? <CheeuCounsel /> : <CheeuCounselEng />}
        />
        <Route
          path="/randd"
          element={language === "kor" ? <RandD /> : <RandDEng />}
        />
        <Route
          path="/propose"
          element={language === "kor" ? <Propose /> : <ProposeEng />}
        />
        {/* 와일드카드 라우트를 마지막에 배치 */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {/* { !location.pathname.includes("/ces") && <Footer language={language} />} */}
      <Footer language={language} />
    </>
  );
}

export default function App() {
  const [language, setLanguage] = useState("kor");

  useEffect(() => {
    // 앱 시작 시 localStorage 초기화
    localStorage.clear();
  }, []);

  return (
    <Router>
      <ReservationProvider>
        <AppRouter language={language} setLanguage={setLanguage} />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          style={{ marginTop: "60px", marginRight: "20px" }}
        />
      </ReservationProvider>
    </Router>
  );
}
