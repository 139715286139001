import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css"; // Swiper 스타일을 가져옵니다.
import "swiper/css/bundle";
import "swiper/css";

import MyValidation from "../components_library/MyValidation";

import ic_propose from "@asset/icon/ic_propose.png";
import logoset_w from "@asset/logo/logo_MindsAI_white.png";
import logoset from "@asset/logo/logo_MindsAI_color.png";

import "./Propose.css";
import TopButton from "../components_library/TopButton";

export default function Propose() {
  return (
    <div className="propose">
      <TopButton />
      <div className="p_main_bg">
        <div class="inner">
          <div class="p_title">
            <img id="p_logo" src={ic_propose} alt="ic_propose" />
            <p id="p_title_01">마인즈에이아이 사업</p>
            <h1 id="p_title_02">제휴 · 제안 문의</h1>
          </div>

          <div class="p_subtitle">
            <h1 id="p_subtitle_01">제휴 · 제안 문의</h1>
            <p id="p_subtitle_02">
              마인즈 내비, 치유 포레스트, 치유 심리상담 사업의 제휴제안 문의를
              하실 수 있습니다.
              <br />
              하단의 양식을 작성하셔서 등록해주시면 확인 후 연락 드리겠습니다.
              <br />
              감사합니다.
            </p>
          </div>
        </div>
      </div>

      <div className="p_form">
        <div className="inner">
          <MyValidation />
        </div>
      </div>
    </div>
  );
}
