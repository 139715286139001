// src/utils/pageMapping.js

export const pageMapping = {
  "/home": "/en",
  "/mindsnavi": "/en/mindsnavi",
  "/cheeuforest": "/en/cheeuforest",
  "/cheeucounsel": "/en/cheeucounsel",
  "/randd": "/en/randd",
  "/propose": "/en/propose",
  "/ces": "/ces",
  // 필요한 경우 추가로 매핑
};

export const reversePageMapping = {
  "/en": "/home",
  "/en/mindsnavi": "/mindsnavi",
  "/en/cheeuforest": "/cheeuforest",
  "/en/cheeucounsel": "/cheeucounsel",
  "/en/randd": "/randd",
  "/en/propose": "/propose",
  "/ces": "/ces",
  // 필요한 경우 추가로 매핑
};
