import React, { useState } from "react";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css"; // Swiper 스타일을 가져옵니다.
import "swiper/css/bundle";
import "swiper/css";

import "./MindsNavi.css";

import TopButton from "../components_library/TopButton";

import ic_mindsnavi from "@asset/icon/ic_mindsnavi.png";
import logoset_w from "@asset/logo/logo_MindsAI_white.png";
import logoset from "@asset/logo/logo_MindsAI_color.png";

import service_process01 from "@asset/image/img_mindsnavi_service_01.png";
import service_process02 from "@asset/image/img_mindsnavi_service_02.png";
import service_process03 from "@asset/image/img_mindsnavi_service_03.png";
import service_process04 from "@asset/image/img_mindsnavi_service_04.png";
import service_process05 from "@asset/image/img_mindsnavi_service_05.png";
import service_arrow from "@asset/image/img_mindsnavi_service_arrow.png";

import program_prove_01 from "@asset/icon/ic_mindsnavi_prove_1.png";
import program_prove_02 from "@asset/icon/ic_mindsnavi_prove_2.png";
import program_prove_03 from "@asset/icon/ic_mindsnavi_prove_3.png";
import program_prove_04 from "@asset/icon/ic_mindsnavi_prove_4.png";
import program_prove_05 from "@asset/icon/ic_mindsnavi_prove_5.png";
import program_prove_06 from "@asset/icon/ic_mindsnavi_prove_6.png";

import program_mockup01 from "@asset/image/img_mindsnavi_mockup1.png";
import program_mockup02 from "@asset/image/img_mindsnavi_mockup2.png";
import program_mockup03 from "@asset/image/img_mindsnavi_mockup3.png";

export default function MindsNavi() {
  return (
    <div className="mindsnavi">
      <TopButton />
      <div className="mm_main_bg">
        <div className="inner">
          <div className="mm_title">
            <img id="mm_logo" src={ic_mindsnavi} alt="ic_mindsnavi" />
            <div className="mm_title_01">
              <p id="mm_title_01-1">PROVE 평가와 타액 호르몬 분석을 통해</p>
              <p id="mm_title_01-2">
                우울증상과 스트레스 상태를 알아볼 수 있는
              </p>
            </div>
            <h1 id="mm_title_02">정신건강 분석평가 프로그램</h1>
          </div>

          <div className="mm_subtitle">
            <h1 id="mm_subtitle_01">마인즈 내비 프로그램</h1>
            <p id="mm_subtitle_02">
              심리평가를 통해 우울 상태와 정신건강상의 취약 및 보호요인을
              확인하고,
              <br />
              타액에 포함된 Cortisol 과 DHEA 호르몬 분비량 분석으로 스트레스
              지수를 객관적으로 파악하여 <br />
              정신건강에 대한 종합결과 및 솔루션 을 제시합니다.
            </p>
            <div className="mm_sub_logo">
              <img id="mm_logoset_w" src={logoset_w} alt="logoset" />
              <img id="mm_logoset" src={logoset} alt="logoset" />
            </div>
          </div>
        </div>
      </div>

      <div className="mm_service">
        <div className="inner">
          <div className="title">서비스 과정</div>
          <div className="service_contents">
            <figure className="service_con">
              <img
                className="service_process"
                src={service_process01}
                alt="service_process01"
              />
              <p className="service_process_text">마인즈 내비 키트 수령</p>
            </figure>

            <figure>
              <img
                className="service_arrow"
                src={service_arrow}
                alt="service_arrow"
              />
            </figure>

            <figure className="service_con">
              <img
                className="service_process"
                src={service_process02}
                alt="service_process02"
              />
              <p className="service_process_text">심리검사지(온라인)작성</p>
            </figure>

            <figure>
              <img
                className="service_arrow"
                src={service_arrow}
                alt="service_arrow"
              />
            </figure>

            <figure className="service_con">
              <img
                className="service_process"
                src={service_process03}
                alt="service_process03"
              />
              <p className="service_process_text">
                주의사항과 설명서 숙지 후 <br />
                타액채취(총 4회)
              </p>
            </figure>

            <figure>
              <img
                className="service_arrow"
                src={service_arrow}
                alt="service_arrow"
              />
            </figure>

            <figure className="service_con">
              <img
                className="service_process"
                src={service_process04}
                alt="service_process04"
              />
              <p className="service_process_text">카트 밀봉, 택배 착불 발송</p>
            </figure>

            <figure>
              <img
                className="service_arrow"
                src={service_arrow}
                alt="service_arrow"
              />
            </figure>

            <figure className="service_con">
              <img
                className="service_process"
                src={service_process05}
                alt="service_process05"
              />
              <p className="service_process_text">
                종합 결과보고서
                <br />
                수령 및 확인
              </p>
            </figure>
          </div>
        </div>
      </div>

      <div className="mm_program_list">
        <div className="inner">
          <div className="title">프로그램 구성</div>
          <div className="mm_program_contents">
            <div className="mm_program_list_program" id="program_01">
              <ul className="mm_program_list_title">1</ul>
              <ul className="mm_program_list_subtitle">
                <li className="mm_program_list_subtitle_01">PROVE TEST</li>
                <li className="mm_program_list_subtitle_02">심리지표 평가</li>
              </ul>
            </div>
            <div className="mm_program_list_program" id="program_02">
              <ul className="mm_program_list_title">2</ul>
              <ul className="mm_program_list_subtitle">
                <li className="mm_program_list_subtitle_01">
                  타액 호르몬 분석
                </li>
                <li className="mm_program_list_subtitle_02">신체지표 분석</li>
              </ul>
            </div>
            <div className="mm_program_list_program" id="program_03">
              <ul className="mm_program_list_title">3</ul>
              <ul className="mm_program_list_subtitle">
                <li className="mm_program_list_subtitle_01">종합 결과보고서</li>
                <li className="mm_program_list_subtitle_02">제공</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* mm_program01 */}
      <div className="mm_program" id="mm_program_01">
        <div className="inner">
          <div className="title">
            <div className="title--number">1</div>
            <div className="title--text">
              <p className="title--text--first">PROVE TEST</p>
              <p className="title--text--second">심리지표 평가</p>
            </div>
          </div>
          <div className="contents">
            <p className="contents__01">자기보고식 설문지 PROVE검사 프로그램</p>
            <p className="contents__02">
              (Depressive symptomatology & PROtective-VulnErable factor Battery
              Test)
            </p>
            <p className="contents__03">
              전문의, 임상심리전문가, 상담심리전문가 & (주)마인즈에이아이
              R&D센터 공동 개발
            </p>
            <img
              className="program-mockup"
              id="program01--mockup"
              src={program_mockup01}
              alt=""
            />
            <p className="contents__04">
              주관적으로 현재 우울 증상을 느끼고 있지 않더라도 개인이 정신건강
              측면에서
              <br />
              취약요인과 보호요인에 대해 인지하고 지속적으로 정신건강 관리를 할
              수 있도록
              <br />
              평가 결과가 자동 산출됩니다.
            </p>
            <div className="contents--emphasisbox">
              *우울증상 및 정신건강 보호-취약요인 선별평가도구 PROVE test
            </div>

            <div className="prove">
              <div className="prove-title">
                <p className="contents__01">PROVE 평가 내용</p>
                <p className="contents__02">
                  우울 상태와 정신건강상의 취약 및 보호요인 평가
                </p>
              </div>
              <div className="prove-contents">
                <div id="prove_1">
                  <img src={program_prove_01} />
                  <ul className="prove-contents_text">
                    <li className="prove_title">우울증상</li>
                    <li className="prove_subtitle">
                      수면상태, 기분, 체중변화, 우울감 및 일상활동 등
                    </li>
                  </ul>
                </div>
                <div id="prove_2">
                  <img src={program_prove_02} />
                  <ul className="prove-contents_text">
                    <li className="prove_title">성인애착 유형</li>
                    <li className="prove_subtitle">
                      친밀한 관계에서의 애착패턴 유형, 안정적인 관계 유지 가능
                      여부
                    </li>
                  </ul>
                </div>
                <div di="prove_3">
                  <img src={program_prove_03} />
                  <ul className="prove-contents_text">
                    <li className="prove_title">성장기 부정적 경험</li>
                    <li className="prove_subtitle">
                      성장기에 학대나 방임 등 심한 정신적 충격에 노출된 경험
                      여부
                    </li>
                  </ul>
                </div>
                <div id="prove_4">
                  <img src={program_prove_04} />
                  <ul className="prove-contents_text">
                    <li className="prove_title">자살 위험성</li>
                    <li className="prove_subtitle">자살 위험성 여부 및 정도</li>
                  </ul>
                </div>
                <div id="prove_5">
                  <img src={program_prove_05} />
                  <ul className="prove-contents_text">
                    <li className="prove_title">마음 헤아리기 능력</li>
                    <li className="prove_subtitle">
                      자신과 타인의 마음을 알아보는 능력
                    </li>
                  </ul>
                </div>
                <div id="prove_6">
                  <img src={program_prove_06} />
                  <ul className="prove-contents_text">
                    <li className="prove_title">회복 탄력성</li>
                    <li className="prove_subtitle">
                      어려운 상황을 극복해내는 마음의 힘
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mm_program" id="mm_program_02">
        <div className="inner">
          <div className="title">
            <div className="title--number">2</div>
            <div className="title--text">
              <p className="title--text--first">타액 호르몬 분석</p>
              <p className="title--text--second">신체지표 분석</p>
            </div>
          </div>
          <div className="contents">
            <p className="contents__01">타액 채취(Cortisol, DHEA)</p>
            <p className="contents__03">
              스트레스에 따른 호르몬 분비량을 분석하여 현재 스트레스 정도 및
              지속기간 등을 <br />
              알아보고, 스트레스 대응 능력을 보다 객관적이고 과학적으로 평가하는
              프로그램
            </p>
            <img
              className="program-mockup"
              id="program02--mockup"
              src={program_mockup02}
              alt=""
            />
            <ul className="contents__04">
              <li>
                {" "}
                타액 수집 튜브/깔때기가 포함된 키트(Kit)를 활용하여 언제
                어디서든 <br />
                간편하고 쉽게 채취한 타액만으로도 신체지표에 대한 정확한 결과
                도출 가능{" "}
              </li>
              <li>
                {" "}
                마인즈 내비는 타액분석 시 정밀함과 측정민감도가 훨씬 높은
                ELISA방식 사용{" "}
              </li>
              <li>
                {" "}
                특허기술을 활용한 자동분석프로그램으로 스트레스 단계 예측,
                Cortisol과 <br /> DHEA 두 호르몬간의 비율을 분석함으로써
                부신피질 기능상태를 총 9단계로 종합적 평가{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="mm_program" id="mm_program_03">
        <div className="inner">
          <div className="title">
            <div className="title--number">3</div>
            <div className="title--text">
              <p className="title--text--first">종합 결과보고서</p>
              <p className="title--text--second">제공</p>
            </div>
          </div>
          <div className="contents">
            <ul className="contents__03">
              <li>
                심리평가 결과와 타액 호르몬 분석 결과를 통합한 분석 보고서
              </li>
              <li>
                현재 스트레스 정도 및 정신건강 상태를 생화학적 지표 + 심리사회적
                지표로 통합분석하여 단계화 표현
              </li>
              <li>
                평가 결과는 정신건강 확인 및 점검, 우울증 사전예방 및 악화 방지
                목적으로 제공됨
              </li>
              <li>
                {" "}
                (주)마인즈에이아이 대표이사 석정호(강남세브란스병원
                정신건강의학과 전문의)의 지도, 감독 하에 진행
              </li>
              <li>
                {" "}
                Minds. NAVI평가 결과는 임상심리전문가(한국임상심리학회 발급) /
                정신건강임상심리사 1급(보건복지부 발급) / <br />
                상담심리사 1급(한국상담심리학회 발급)과 같이 공인된 자격증을
                취득한 전문가의 검수를 거쳐 대상자에게 제공
              </li>
            </ul>
            <div className="contents--emphasisbox">
              *「마인즈 내비(Minds. NAVI)결과지」저작권 등록
            </div>
            <img
              className="program-mockup"
              id="program03--mockup"
              src={program_mockup03}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
